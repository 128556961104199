import React from "react";
import logo from './logo.svg';
import './App.css';
import ChatBotRobot from './chatbot/ChatBotRobot';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import ChatBotInterface from './chatbot/ChatBotInterface';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import Routes from './routes/Routes';

function App() {
  return (
    <Router>
      <div>
      <ToastContainer limit={1} />
        <Routes>
          <Route path="/" element={<ChatBotRobot />} />
          <Route path="/chat-bot-interface/:id/:name" element={<ChatBotInterface />} />
        </Routes>
      </div>
    </Router>

  );
}

export default App;
