import React from "react";
import { Link } from "react-router-dom";
//import { Launcher } from 'react-chat-window';
import io from 'socket.io-client';
import logo from '../Images/logo.png';
import small_icon1 from '../Images/small_icon1.png';
import small_icon2 from '../Images/small_icon2.png';
import small_icon3 from '../Images/small_icon3.png';
import small_icon4 from '../Images/small_icon4.png';
import small_icon5 from '../Images/small_icon5.png';
import small_icon6 from '../Images/small_icon6.png';
import banner_img from '../Images/banner_img.png';
import robot_img6 from '../Images/robot_img6.png';

class ChatBotRobot extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            messageList: [],
            //  socket: io("https://chatbot-backend.itechnolabs.tech"),
            room: "user1",
            sessionid: '',
            type : '1',
            inputAnyName : ''
        }
    }

    // UNSAFE_componentWillMount() {
    //     this._sendMessage("Hey there !");
    // }

    componentDidMount() {
        //   console.log(this.state.socket);
        //    this.state.socket.connect(true);

        // client-side
        // this.state.socket.on("connect", () => {
        //     console.log(this.state.socket.id); // x8WIv7-mJelg7on_ALbx

        //     var sessionid = this.state.socket.io.engine.id;
        //     console.log(sessionid);

        //     this.setState({ sessionid: sessionid });
        //     // console.log(client);
        // });

        // this.state.socket.on('bot_uttered', async (response) => {
        //     console.log("Bot uttered:", response);
        //     console.log(response.text);

        // await this.setState({
        //     messageList: [...this.state.messageList]
        // })
        // if(response.text) {
        //     this._sendMessage(response.text);
        // }

        // if (response.text) {
        //     appendMessage(response.text, "received");
        // }
        // if (response.attachment) {
        //     appendImage(response.attachment.payload.src, "received");
        // }
        // if (response.quick_replies) {
        //     appendQuickReplies(response.quick_replies);
        // }
        //  });

        //  this.state.socket.emit('join', this.state.room);
        // this.state.socket.on("send-msg-response", async (msg) => {
        //     this.state.messageList.pop();
        //     await this.setState({
        //         messageList: [...this.state.messageList]
        //     })
        //     this._sendMessage(msg);
        // })
    }

    onTypeSelected = (e) => {
        this.setState({ type: e.target.value });
    }

    handleInput = (e) => {
        this.setState({ inputAnyName: e.target.value });
    }

    // async _onMessageWasSent(message) {
    //     await this.setState({
    //         messageList: [...this.state.messageList, message]
    //     })
    //     // this._sendMessage("••••");
    //     // this._sendMessage("Please wait, backend connection is yet to be done");
    //     await this.state.socket.emit('user_uttered', { message: message.data.text, customData: { language: "en" }, session_id: this.state.sessionid })
    // }

    // _sendMessage(text) {
    //     if (text.length > 0) {
    //         this.setState({
    //             messageList: [...this.state.messageList, {
    //                 author: 'them',
    //                 type: 'text',
    //                 data: { text }
    //             },]
    //         })
    //     }
    // }

    render() {
        const { type, inputAnyName } = this.state;

        return (
            <div>
                <header>
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container">
                            <a className="navbar-brand" href=""><img src={logo} /></a>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                    <li className="nav-item">
                                        <a className="nav-link active" aria-current="page" href="#"><img src={small_icon1} /><p>Oppty<br /> Says</p></a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link active" aria-current="page" href="#"><img src={small_icon2} /><p>Inbox</p></a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link active" aria-current="page" href="#"><img src={small_icon3} /><p>Notification</p></a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link active" aria-current="page" href="#"><img src={small_icon4} /><p>Jobs</p></a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link active" aria-current="page" href="#"><img src={small_icon5} /><p className="active5">Profile</p></a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link active" aria-current="page" href="#"><img src={small_icon6} /><p>Sign Out</p></a>
                                    </li>

                                </ul>

                            </div>
                        </div>
                    </nav>
                </header>
                <section className="chatbox_outer">
                    <div className="container">
                        <div className="chat_bi7">
                            <h5>AI Chatbot</h5>
                            <h2>The smarter way to hire</h2>
                            <p>Have a question about OppTy? Need help scheduling interviews? Jarvis <br />
                                is here to solve all your hiring needs.</p>
                            <div className="btn_iny7">
                                <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    Start Chatting with Jarvis
                                </button>

                                <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                {/* <h5 className="modal-title" id="exampleModalLabel">Modal title</h5> */}
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>

                                            <div className="modal-body">
                                                <p><b>Please select the interface </b></p>
                                                <div className="radio_btn_outer">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="0" checked={type == '0'} onChange={this.onTypeSelected} />
                                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                            Candidate
                                                        </label>
                                                    </div>

                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="1" checked={type == '1'} onChange={this.onTypeSelected} />
                                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                            Hiring Manager
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="form-check candname">
                                                    <label className="form-check">
                                                        Enter Name
                                                    </label>
                                                    <input className="form-input" type="text" placeholder="Enter name..." name="input-name" value={inputAnyName} onChange={this.handleInput} />
                                                </div>
                                            </div>

                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                                <Link to={`${'/chat-bot-interface/' + type + '/' + inputAnyName}`}><button type="button" className="btn btn-primary" data-bs-dismiss="modal" aria-label="Close">Let's Go</button></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="imgh7">
                                <img src={robot_img6} />
                            </div>
                        </div>
                    </div>
                </section>
                </div>
     

            // <div id="chatbox" className="chatbox">
            //     <Launcher
            //         agentProfile={{
            //             teamName: 'Chatbot',
            //             imageUrl: 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png'
            //         }}
            //         onMessageWasSent={this._onMessageWasSent.bind(this)}
            //         messageList={this.state.messageList}
            //         //showEmoji
            //     /> 
            // </div>

            // <Widget
            //     initPayload={"/get_started"}
            //     socketUrl={"https://chatbot-backend.itechnolabs.tech"}
            //     socketPath={"/socket.io/"}
            //     customData={{ "language": "en" }} // arbitrary custom data. Stay minimal as this will be added to the socket
            //     title={"Title"}
            //     embedded="true"
            //     showMessageDate={true}
            // />
            //     />
            // </div>
        );
    }
}
export default ChatBotRobot;