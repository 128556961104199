import './chatBot.css';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, Link } from "react-router-dom";
import { Carousel } from '@trendyol-js/react-carousel';
import { IoMdSend } from 'react-icons/io';
import { BiBot, BiUser } from 'react-icons/bi';
import avtar from '../Images/avtar.png';
import c1 from '../Images/c1.png';
import c2 from '../Images/c2.png';
import c3 from '../Images/c3.png';
import dotted from '../Images/dotted.gif';
import moment from 'moment';
import shape from '../Images/Shape.png';
import check from '../Images/check.png';
import small_doc from '../Images/small_doc.png';
import close from '../Images/close.png';
import step51 from '../Images/step51.png';
import step52 from '../Images/step52.png';
import step53 from '../Images/step53.png';
import check_inner from '../Images/check_inner.png';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactTooltip from "react-tooltip";

const live_base_url = 'https://chatbot-backend2.itechnolabs.tech';
const dev_base_url = 'https://chatbot-backend2-development.itechnolabs.tech';

function ChatBotInterface() {
    const paramvalue = useParams();
    const [chat, setChat] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [botTyping, setbotTyping] = useState(false);
    const [initialBit, setInitialBit] = useState(false);
    const [justNow, setJustNow] = useState(true);
    const navigate = useNavigate();
    const [hiringname, setHiringName] = useState(paramvalue.name);
    const [candname, setCandName] = useState(paramvalue.name);
    const [selectedFile, setSelectedFile] = useState();
    const [isFileAccepted, setIsAccepted] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [getJob, setJob] = useState(false);
    const [salaryCompensation, setSalaryCompensation] = useState('');
    const [yearlySalaryCompensation, setYearlyCompensation] = useState('');
    const [getJobDesc, setJobDesc] = useState('');
    const [commission, setCommission] = useState('');
    const [getAdditionalCommission, setAdditionalCommission] = useState('');
    const [getRequirements, setOtherRequirements] = useState('');
    const [getJDTitle, setJDTitle] = useState('');
    const [jobDescArray, setJobDescArray] = useState([]);
    const [budget, setBudget] = useState('');
    const [getOrganisation, setOrganisation] = useState('');
    const [jobExpectations, setJobExpectations] = useState('');
    const [teamSize, setTeamSize] = useState('');
    const [supervisorInput, setSupervisorInput] = useState('');
    const [companyStructure, setCompanyStructure] = useState('');
    const [questionsHiring, setQuestionsHiring] = useState([]);
    const [candidateQuestionsHiring, setCandidateQuestionsHiring] = useState([]);
    const [nameReferee, setNameReferee] = useState('');
    const [emailReferee, setEmailReferee] = useState('');
    const [dateSelection, setDateSelection] = useState();
    const [timeSelection, setTimeSelection] = useState();
    const [customCandidates, setCustomCandidates] = useState([]);
    const [minDate, setMinDate] = useState();
    const [dateSelection1, setDateSelection1] = useState();
    const [dateSelection2, setDateSelection2] = useState();
    const [dateSelection3, setDateSelection3] = useState();
    const [timeSelection1, setTimeSelection1] = useState();
    const [timeSelection2, setTimeSelection2] = useState();
    const [timeSelection3, setTimeSelection3] = useState();
    const [timeSlots, setTimeSlots] = useState(0);
    const [userType, setUserType] = useState([]);
    const [feedback_hr_candidate_xhours, setFeedbackXHours] = useState('');
    const [hrProfileExperience, setHrProfileExperience] = useState('');
    let [roleToOffer, setRoleToOffer] = useState(false);
    const [showRoleToOfferInput, setShowRoleToOfferInput] = useState(false);
    const [roleOfferInput, setRoleOfferInput] = useState('');
    const [monthlyBasicForm, setMonthlyBasicForm] = useState('');
    const [annualWageForm, setAnnualWageForm] = useState('');
    const [additionalWageComponentForm, setAdditionalWageComponent] = useState('');
    const [additionalPrivilegesForm, setAdditionalPrivilegesForm] = useState('');
    const [offerYesNegotiableForm, setOfferYesNegotiable] = useState(true);
    const [offerNoNegotiableForm, setOfferNoNegotiable] = useState(false);
    const [jobTitleBackend, setJobTitleBackend] = useState('');
    const [contactCandidateNumber, setContactCandidateNumber] = useState('');
    const [contactCandidateEmail, setContactCandidateEmail] = useState('');
    const [candidate_questions_textbox, setCandidateQuestionsTextbox] = useState('');
    const [inputCustomSection, setInputCustomSection] = useState();
    const [expectedSalary, setExpectedSalary] = useState('');
    const [offerAcceptedButtons, setOfferAcceptedButtons] = useState([]);
    const [feedbackFromHiring, setFeedbackFromHiring] = useState('');

    const textInput = useRef(null);

    useEffect(() => {
        const objDiv = document.getElementById('messageArea');
        objDiv.scrollTop = objDiv.scrollHeight;

        if (initialBit == false) {
            setChat([]);
            const recipient_msg = `${dotted}`;
            var msg = [{
                "recipient_id": 1,
                "text": recipient_msg
            }]

            const response_temp = { sender: "bot", sender_id: paramvalue.id == '1' ? hiringname : candname, recipient_id: 1, type: 'initial_image', msg: msg, buttons: [], text_type: '', checkEnable: false, datetime: '' };

            setbotTyping(true);

            setChat(chat => [response_temp]);
            setInitialBit(true);
            if (paramvalue.id == '0') {
                rasaAPI(candname, "hello");
            }

            setTimeout(
                () => {
                    getInitialText();
                }, 3000
            );
        }

    }, [chat]);

    function getInitialText() {
        setChat([]);
        let response_temp = {};
        if (paramvalue.id == '1') {
            // const recipient_msg = `Hi ${hiringname}, I'm Jarvis!`;
            // var msg = [{
            //     "recipient_id": 1,
            //     "text": recipient_msg
            // }]

            //  response_temp = { sender: "bot", sender_id: paramvalue.id == '1' ? hiringname : candname, recipient_id: 1, type: 'initialhiringtext', msg: msg, buttons: [], text_type: '', checkEnable: '', datetime: moment().format("HH:mm") };
            rasaAPI(hiringname, "/hm_login");
        } else {
            rasaAPI(candname, "/candidate_login");
        }

        setbotTyping(true);

        //  setChat(chat => [...chat, response_temp]);
    }

    const onSelectchange = (e) => {
        if (e.target.value != '') {
            const request_temp = { sender: "user", sender_id: paramvalue.id == '1' ? hiringname : candname, recipient_id: paramvalue.id == '1' ? hiringname : candname, type: 'text', msg: [{ recipient_id: paramvalue.id == '1' ? hiringname : candname, text: e.target.value }], buttons: [], text_type: 'text', checkEnable: false, datetime: moment().format("HH:mm") };

            setChat(chat => [...chat, request_temp]);
            setbotTyping(true);
            rasaAPI(paramvalue.id == '1' ? hiringname : candname, e.target.value);
            setJDTitle(e.target.value);
            getJD(e.target.value);
        } else {
            alert('Please select the relevant template from dropdown.')
        }

    }

    const onJobSelectchange = (e, type) => {
        let split = (e.target.value).split("_");
        const request_temp = { sender: "user", sender_id: paramvalue.id == '1' ? hiringname : candname, recipient_id: paramvalue.id == '1' ? hiringname : candname, type: 'text', msg: [{ recipient_id: paramvalue.id == '1' ? hiringname : candname, text: split[1] }], buttons: [], text_type: 'text', checkEnable: false, datetime: moment().format("HH:mm") };

        setChat(chat => [...chat, request_temp]);
        setbotTyping(true);
        rasaAPI(paramvalue.id == '1' ? hiringname : candname, split[0]);

        let views = chat.slice();
        for (let i in views) {
            if (views[i].sender == 'bot' && views[i].type == type) {
                views[i].checkEnable = true;
                setChat(views);
                break;
            }
        }
    }

    const onButtonSelect = (btn, type) => {
        setbotTyping(true);

        var msg = [{
            "recipient_id": paramvalue.id == '1' ? hiringname : candname,
            "text": btn.title
        }]

        const request_temp = { sender: "user", sender_id: paramvalue.id == '1' ? hiringname : candname, recipient_id: paramvalue.id == '1' ? hiringname : candname, type: 'text', msg: msg, buttons: [], text_type: 'text', checkEnable: false, datetime: moment().format("HH:mm") };

        setChat(chat => [...chat, request_temp]);

        rasaAPI(paramvalue.id == '1' ? hiringname : candname, btn.payload);
        let views = chat.slice();
        for (let i in views) {
            if (views[i].sender == 'bot' && views[i].type == type) {
                views[i].checkEnable = true;
                setChat(views);
                break;
            }
        }
    }

    const candidateSelectnterview = (data, type) => {
        setbotTyping(true);

        var msg = [{
            "recipient_id": paramvalue.id == '1' ? hiringname : candname,
            "text": `${data.first_name} candidate selected`
        }]

        const request_temp = { sender: "user", sender_id: paramvalue.id == '1' ? hiringname : candname, recipient_id: paramvalue.id == '1' ? hiringname : candname, type: 'text', msg: msg, buttons: [], text_type: 'text', checkEnable: false, datetime: moment().format("HH:mm") };

        setChat(chat => [...chat, request_temp]);

        rasaAPI(paramvalue.id == '1' ? hiringname : candname, `candidate application ID: ${data.Appl_ID}`);
        let views = chat.slice();
        for (let i in views) {
            if (views[i].sender == 'bot' && views[i].type == type) {
                views[i].checkEnable = true;
                setChat(views);
                break;
            }
        }
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();

        var newmsg = [{
            recipient_id: paramvalue.id == '1' ? hiringname : candname,
            text: inputMessage
        }]

        const request_temp = { sender: "user", sender_id: paramvalue.id == '1' ? hiringname : candname, recipient_id: paramvalue.id == '1' ? hiringname : candname, type: 'text', msg: newmsg, buttons: [], text_type: "text", checkEnable: false, datetime: moment().format("HH:mm") };

        if (inputMessage !== "") {
            setbotTyping(true);

            if (chat[chat.length - 1].text_type == 'job_position') {
                setJDTitle(inputMessage);
                rasaAPI(paramvalue.id == '1' ? hiringname : candname, "I want to hire " + inputMessage);
            } else {
                rasaAPI(paramvalue.id == '1' ? hiringname : candname, inputMessage);
            }

            setChat(chat => [...chat, request_temp]);
            setInputMessage('');

            let views = chat.slice();
            for (let i in views) {
                if (views[i].sender == 'bot' && views[i].type == 'jd_confirm') {
                    views[i].checkEnable = true;
                    setChat(views);
                    break;
                }
            }

            if (getJob) {
                getJobTitles(inputMessage);
            }

        } else {
            window.alert("Please enter valid message");
        }
    }

    const submitData = (data, bit, user_type) => {
        setbotTyping(true);

        var msg = [{
            "recipient_id": paramvalue.id == '1' ? hiringname : candname,
            "text": "Done"
        }]
        if (bit == 0) {
            if (data != "") {
                rasaAPI(paramvalue.id == '1' ? hiringname : candname, `Additional JD Requirements: ${data}`);
            } else {
                toast('Please input in the box to proceed.', {
                    position: "top-right",
                    autoClose: 3000,
                    type: 'error',
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
                return;
            }

        } else if (bit == 1) {
            rasaAPI(paramvalue.id == '1' ? hiringname : candname, `Commision ${data} percent Benefits: ${getAdditionalCommission}`);
        } else if (bit == 2) {
            msg = [{
                "recipient_id": paramvalue.id == '1' ? hiringname : candname,
                "text": `${budget} %`
            }]
            rasaAPI(paramvalue.id == '1' ? hiringname : candname, `+ - ${budget} %`);
        } else if (bit == 3) {
            msg = [{
                "recipient_id": paramvalue.id == '1' ? hiringname : candname,
                "text": data
            }]
            rasaAPI(paramvalue.id == '1' ? hiringname : candname, data);
        } else if (bit == 4) {
            msg = [{
                "recipient_id": paramvalue.id == '1' ? hiringname : candname,
                "text": data
            }]
            rasaAPI(paramvalue.id == '1' ? hiringname : candname, data);
        } else if (bit == 5) {
            msg = [{
                "recipient_id": paramvalue.id == '1' ? hiringname : candname,
                "text": `${data} Employees`
            }]
            rasaAPI(paramvalue.id == '1' ? hiringname : candname, `${data} Employees`);
        } else if (bit == 6) {
            msg = [{
                "recipient_id": paramvalue.id == '1' ? hiringname : candname,
                "text": data == "/deny" ? "Staff Replacement" : "Added Headcount"
            }]
            rasaAPI(paramvalue.id == '1' ? hiringname : candname, data);
        } else if (bit == 7) {
            msg = [{
                "recipient_id": paramvalue.id == '1' ? hiringname : candname,
                "text": data == "/affirm" ? "Team Member" : "Management"
            }]
            rasaAPI(paramvalue.id == '1' ? hiringname : candname, data);
        } else if (bit == 8) {
            if (data != "") {
                msg = [{
                    "recipient_id": paramvalue.id == '1' ? hiringname : candname,
                    "text": data
                }]
                rasaAPI(paramvalue.id == '1' ? hiringname : candname, `Position of stakeholder or supervisor : ${data}`);
            } else {
                toast('Please input email/name in the box to proceed.', {
                    position: "top-right",
                    autoClose: 3000,
                    type: 'error',
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
                return;
            }

        } else if (bit == 9) {
            msg = [{
                "recipient_id": paramvalue.id == '1' ? hiringname : candname,
                "text": data
            }]
            rasaAPI(paramvalue.id == '1' ? hiringname : candname, data);
        } else if (bit == 10) {
            let responseSent = `${questionsHiring[0].value} : ${questionsHiring[0].yesclass == "button highlight" ? "/affirm" : (questionsHiring[0].noclass == "button highlight" ? "/deny" : "")}` + ' ';

            questionsHiring.length > 0 && questionsHiring.map((message, index) => {
                if (index == 1 && responseSent != '') {
                    responseSent = responseSent + ' : ' + `${message.value} : ${message.yesclass == "button highlight" ? "/affirm" : (message.noclass == "button highlight" ? "/deny" : "")}` + ' ';
                }

            });

            rasaAPI(paramvalue.id == '1' ? hiringname : candname, responseSent);

        } else if (bit == 11) {
            let responseSent = `${candidateQuestionsHiring[0].yesclass == "button highlight" ? "Yes" : (candidateQuestionsHiring[0].noclass == "button highlight" ? "No" : "")}` + ' ';

            candidateQuestionsHiring.length > 0 && candidateQuestionsHiring.map((message, index) => {
                if (index == 1 && responseSent != '') {
                    responseSent = responseSent + ' : ' + `${message.yesclass == "button highlight" ? "Yes" : (message.noclass == "button highlight" ? "No" : "")}` + ' ';
                }

            });

            rasaAPI(paramvalue.id == '1' ? hiringname : candname, responseSent);

        } else if (bit == 12) {

            if (data != "") {
                msg = [{
                    "recipient_id": paramvalue.id == '1' ? hiringname : candname,
                    "text": "Done"
                }]
                rasaAPI(paramvalue.id == '1' ? hiringname : candname, data);
            } else {
                toast('Please input email/name in the box to proceed.', {
                    position: "top-right",
                    autoClose: 3000,
                    type: 'error',
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
                return;
            }

        } else if (bit == 13) {
            if (timeSlots == 1) {
                if (dateSelection && timeSelection) {
                    msg = [{
                        "recipient_id": paramvalue.id == '1' ? hiringname : candname,
                        "text": `date and time ${dateSelection} ${timeSelection}`
                    }]
                    rasaAPI(paramvalue.id == '1' ? hiringname : candname, `date and time ${dateSelection} ${timeSelection}`);
                } else {
                    toast('Please input all datetime slots properly.', {
                        position: "top-right",
                        autoClose: 3000,
                        type: 'error',
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                    return;
                }

            } else if (timeSlots == 2) {
                if (dateSelection1 && timeSelection1) {
                    msg = [{
                        "recipient_id": paramvalue.id == '1' ? hiringname : candname,
                        "text": `date and time ${dateSelection1} ${timeSelection1}`
                    }]
                    rasaAPI(paramvalue.id == '1' ? hiringname : candname, `date and time ${dateSelection1} ${timeSelection1}`);
                } else {
                    toast('Please input all datetime slots properly.', {
                        position: "top-right",
                        autoClose: 3000,
                        type: 'error',
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                    return;
                }

            } else if (timeSlots == 3) {
                if (dateSelection2 && timeSelection2) {
                    msg = [{
                        "recipient_id": paramvalue.id == '1' ? hiringname : candname,
                        "text": `date and time ${dateSelection2} ${timeSelection2}`
                    }]
                    rasaAPI(paramvalue.id == '1' ? hiringname : candname, `date and time ${dateSelection2} ${timeSelection2}`);
                } else {
                    toast('Please input all datetime slots properly.', {
                        position: "top-right",
                        autoClose: 3000,
                        type: 'error',
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                    return;
                }

            }

        } else if (bit == 14) {
            if (data && timeSelection1 && dateSelection2 && timeSelection2 && dateSelection3 && timeSelection3) {
                msg = [{
                    "recipient_id": paramvalue.id == '1' ? hiringname : candname,
                    "text": `date and time = ${data} ${timeSelection1}, ${dateSelection2} ${timeSelection2} and lastly ${dateSelection3} ${timeSelection3}`
                }]
                rasaAPI(paramvalue.id == '1' ? hiringname : candname, `date and time = ${data} ${timeSelection1} = ${dateSelection2} ${timeSelection2} = ${dateSelection3} ${timeSelection3}`);
            } else {
                toast('Please input all datetime slots properly.', {
                    position: "top-right",
                    autoClose: 3000,
                    type: 'error',
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
                return;
            }


        } else if (bit == 15) {
            msg = [{
                "recipient_id": paramvalue.id == '1' ? hiringname : candname,
                "text": data
            }]
            rasaAPI(paramvalue.id == '1' ? hiringname : candname, data);
        } else if (bit == 16) {
            msg = [{
                "recipient_id": paramvalue.id == '1' ? hiringname : candname,
                "text": 'Experience - ' + data
            }]
            rasaAPI(paramvalue.id == '1' ? hiringname : candname, data);
        } else if (bit == 17) {
            msg = [{
                "recipient_id": paramvalue.id == '1' ? hiringname : candname,
                "text": 'Done'
            }]

            let tempData = `Title == ${roleOfferInput != '' ? roleOfferInput : 'skip'} == Monthly == ${monthlyBasicForm} == Annual == ${annualWageForm != '' ? annualWageForm : 'skip'} == Commission == ${additionalWageComponentForm != '' ? additionalWageComponentForm : 'skip'} == benefits == ${additionalPrivilegesForm != '' ? additionalPrivilegesForm : 'skip'} == negotiable == ${offerYesNegotiableForm == true ? 'yes' : (offerNoNegotiableForm == true ? 'no' : 'skip')}`;

            rasaAPI(paramvalue.id == '1' ? hiringname : candname, tempData);
        } else if (bit == 18) {
            msg = [{
                "recipient_id": paramvalue.id == '1' ? hiringname : candname,
                "text": data
            }]
            rasaAPI(paramvalue.id == '1' ? hiringname : candname, data);
        } else if (bit == 19) {
            if (dateSelection && timeSelection) {
                msg = [{
                    "recipient_id": paramvalue.id == '1' ? hiringname : candname,
                    "text": `date and time ${dateSelection} ${timeSelection}`
                }]
                rasaAPI(paramvalue.id == '1' ? hiringname : candname, `${dateSelection} ${timeSelection}`);
            } else {
                toast('Please input all datetime slots properly.', {
                    position: "top-right",
                    autoClose: 3000,
                    type: 'error',
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
                return;
            }
        } else if (bit == 20) {
            msg = [{
                "recipient_id": paramvalue.id == '1' ? hiringname : candname,
                "text": data
            }]
            rasaAPI(paramvalue.id == '1' ? hiringname : candname, data);
        } else if (bit == 21) {
            msg = [{
                "recipient_id": paramvalue.id == '1' ? hiringname : candname,
                "text": data
            }]
            rasaAPI(paramvalue.id == '1' ? hiringname : candname, data);
        } else if (bit == "skip") {
            msg = [{
                "recipient_id": paramvalue.id == '1' ? hiringname : candname,
                "text": "Skipped"
            }]
            rasaAPI(paramvalue.id == '1' ? hiringname : candname, data);
        }

        let views = chat.slice();
        for (let i in views) {
            if (views[i].sender == 'bot' && views[i].type == user_type) {
                views[i].checkEnable = true;
                setChat(views);
                break;
            }
        }

        if (bit != 10 && data) {
            const request_temp = { sender: "user", sender_id: paramvalue.id == '1' ? hiringname : candname, recipient_id: paramvalue.id == '1' ? hiringname : candname, type: 'text', msg: msg, buttons: [], text_type: 'text', checkEnable: false, datetime: moment().format("HH:mm") };

            setChat(chat => [...chat, request_temp]);
        }

    }

    const rasaAPI = async function handleClick(name, msg) {
        //chatData.push({sender : "user", sender_id : name, msg : msg});
        setbotTyping(true);

        await fetch(`${dev_base_url}/bot/webhooks/rest/custom-webhook`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "same-origin",
            body: JSON.stringify({ "sender": name, "message": msg, datetime: moment().format() }),
        })
            .then(response => response.json())
            .then((response) => {
                if (msg != "hello") {
                    if (response.bot_response && response.bot_response.length > 0) {
                        let user_type = response.user_text;

                        let arr = response.bot_response;
                        let response_temp = {}, response_temp1 = {}, custom_candidates = [];

                        response.bot_response.forEach(element => {
                            if (element.buttons && element.buttons.length > 0 && user_type != "replacement_opening" && user_type != "conributor_role" && user_type != "initial_company_candidate_questions" && user_type != "hiring_candidate_confirm_slots_buttons" && user_type != "offered_candidate_options" && user_type != "hiring_offer_accepted" && user_type != "approach_hiring_negotiate_offer") {
                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'text_button', msg: arr, buttons: element.buttons, text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };
                            } else if (user_type == 'Upload') {
                                arr = [{
                                    recipient_id: response.bot_response[0].recipient_id,
                                    text: "Just drag and drop your document below:"
                                }]
                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'upload', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };
                            } else if (user_type == 'jd_load') {
                                arr = [{
                                    recipient_id: response.bot_response[0].recipient_id,
                                    text: response.bot_response[0].text
                                }]
                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'jd_load', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };
                            } else if (user_type == 'Enter Manually') {
                                arr = [{
                                    recipient_id: response.bot_response[0].recipient_id,
                                    text: "Please enter job description in the input box below"
                                }]
                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'text', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };

                                setUserType(userType => [...userType, user_type]);

                            } else if (user_type == 'monthly_compensation') {

                                arr = [{
                                    recipient_id: response.bot_response[0].recipient_id,
                                    text: response.bot_response[0].text
                                }]
                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'monthly_compensation', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };
                            } else if (user_type == 'incentive') {
                                arr = [{
                                    recipient_id: response.bot_response[0].recipient_id,
                                    text: response.bot_response[0].text
                                }]
                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'incentive', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };
                            } else if (user_type == 'other_requirements') {
                                arr = [{
                                    recipient_id: response.bot_response[0].recipient_id,
                                    text: response.bot_response[0].text
                                }]
                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'other_requirements', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };
                            } else if (user_type == 'budget_package') {
                                arr = [{
                                    recipient_id: response.bot_response[0].recipient_id,
                                    text: response.bot_response[0].text
                                }]
                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'budget_package', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };
                            } else if (user_type == 'specific_organisation') {
                                arr = [{
                                    recipient_id: response.bot_response[0].recipient_id,
                                    text: response.bot_response[0].text
                                }]
                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'specific_organisation', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };
                            } else if (user_type == 'job_expectations') {
                                arr = [{
                                    recipient_id: response.bot_response[0].recipient_id,
                                    text: response.bot_response[0].text
                                }]
                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'job_expectations', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };
                            } else if (user_type == 'team_size') {
                                arr = [{
                                    recipient_id: response.bot_response[0].recipient_id,
                                    text: response.bot_response[0].text
                                }]
                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'team_size', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };
                            } else if (user_type == 'replacement_opening') {
                                arr = [{
                                    recipient_id: response.bot_response[0].recipient_id,
                                    text: response.bot_response[0].text
                                }]
                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'replacement_opening', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };
                            } else if (user_type == 'conributor_role') {
                                arr = [{
                                    recipient_id: response.bot_response[0].recipient_id,
                                    text: response.bot_response[0].text
                                }]
                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'conributor_role', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };
                            } else if (user_type == "stakeholder_role") {
                                arr = [{
                                    recipient_id: response.bot_response[0].recipient_id,
                                    text: response.bot_response[0].text
                                }]
                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'stakeholder_role', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };
                            } else if (user_type == "company_structure") {
                                arr = [{
                                    recipient_id: response.bot_response[0].recipient_id,
                                    text: response.bot_response[0].text
                                }]
                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'company_structure', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };
                            } else if (user_type == "hiring_specific_questions") {
                                arr = [{
                                    recipient_id: response.bot_response[0].recipient_id,
                                    text: '',
                                    name: 'Question1',
                                    value: '',
                                    yesclass: 'button',
                                    noclass: 'button'
                                },
                                ]

                                setQuestionsHiring(arr);
                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'hiring_specific_questions', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };

                            } else if (user_type == "initial_company_candidate_questions") {
                                let new_arr = [];
                                arr.map((item, key) => {
                                    if (key >= 1) {
                                        if (item && item.text) {
                                            new_arr.push({
                                                recipient_id: item.recipient_id,
                                                yesclass: 'button',
                                                noclass: 'button',
                                                text: item.text
                                            })
                                        }
                                    }
                                });

                                let msgarr = [{
                                    recipient_id: response.bot_response[0].recipient_id,
                                    text: response.bot_response[0].text
                                }]

                                if (new_arr.length > 0) {
                                    setCandidateQuestionsHiring(new_arr);
                                }

                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'initial_company_candidate_questions', msg: msgarr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };

                            } else if (user_type == "candidate_email_referee") {
                                arr = [{
                                    recipient_id: response.bot_response[0].recipient_id,
                                    text: response.bot_response[0].text
                                }]
                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'candidate_email_referee', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };
                            } else if (user_type == "job_interview_book_hr") {
                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'job_interview_book_hr', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };
                            } else if (user_type == "view_profile_candidates_interview_book") {
                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'view_profile_candidates_interview_book', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };

                                if (element.custom) {
                                    console.log(element.custom.profile_pic)

                                    if (element.custom.account_ID == 151) {
                                        element.custom.image = `${c1}`;
                                    }
                                    if (element.custom.account_ID == 28) {
                                        element.custom.image = `${c2}`;
                                    }
                                    if (element.custom.account_ID == 25) {
                                        element.custom.image = `${c3}`;
                                    }

                                    custom_candidates.push(element.custom);

                                    //if(element.custom.profile_pic != "") {
                                    // const getBase64API = async function getBase64(element) {
                                    //     await fetch(`${element.custom.profile_pic}`, {
                                    //         method: 'GET',
                                    //         headers: {
                                    //             'Content-Type': 'application/json'
                                    //         }
                                    //     })
                                    //     .then((response) => response.json())
                                    //     .then((response) => {
                                    //         console.log(response);
                                    //         if(response.payload != null) {
                                    //             return response.payload;
                                    //         }

                                    //     })
                                    //     .catch((error) => {
                                    //         console.error('Error:', error);
                                    //     });
                                    // }

                                    // }
                                    //if(getBase64API) {

                                    // }

                                    // setCustomCandidates(element.custom);
                                }
                            } else if (user_type == "date_time_picker_book_interview_hr") {
                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'date_time_picker_book_interview_hr', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };

                                setTimeSlots(timeSlots + 1);

                                var dtToday = new Date();

                                var month = dtToday.getMonth() + 1;
                                var day = dtToday.getDate();
                                var year = dtToday.getFullYear();
                                if (month < 10)
                                    month = '0' + month.toString();
                                if (day < 10)
                                    day = '0' + day.toString();

                                var maxDate = year + '-' + month + '-' + day;
                                setMinDate(maxDate);

                            } else if (user_type == "candidate_interview_invitation") {
                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'candidate_interview_invitation', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };

                            } else if (user_type == "candidate_interview_slots_datetime") {
                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'candidate_interview_slots_datetime', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };

                            } else if (user_type == "hiring_candidate_confirm_slots_buttons") {

                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'hiring_candidate_confirm_slots_buttons', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };

                            } else if (user_type == "x_hours_feedback_textarea") {

                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'x_hours_feedback_textarea', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };

                            } else if (user_type == "reject_candidate_dropdown") {

                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'reject_candidate_dropdown', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };

                            } else if (user_type == "experience_before_offer_profile") {

                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'experience_before_offer_profile', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };

                            } else if (user_type == "joblevel_before_offer_profile") {

                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'joblevel_before_offer_profile', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };

                            } else if (user_type == "jobtype_before_offer_profile") {

                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'jobtype_before_offer_profile', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };

                            } else if (user_type == "qualification_before_offer_profile") {

                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'qualification_before_offer_profile', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };

                            } else if (user_type == "industrytype_before_offer_profile") {

                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'industrytype_before_offer_profile', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };

                            } else if (user_type == "average_salary_prediction") {

                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'average_salary_prediction', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };

                            } else if (user_type == "offer_hiring_form_full_edit") {
                                arr = [{
                                    recipient_id: response.bot_response[0].recipient_id,
                                    text: response.bot_response[0].text
                                }]

                                if (element.custom) {
                                    if (element.custom.Title != 'skip') {
                                        setRoleOfferInput(element.custom.Title);
                                        setRoleToOffer(true);
                                    }
                                    if (element.custom.Monthly) {
                                        setMonthlyBasicForm(element.custom.Monthly);
                                    }
                                    if (element.custom.Annual != 'skip') {
                                        setAnnualWageForm(element.custom.Annual);
                                    }
                                    if (element.custom.Commission != 'skip') {
                                        setAdditionalWageComponent(element.custom.Commission);
                                    }
                                    if (element.custom.benefits != 'skip') {
                                        setAdditionalPrivilegesForm(element.custom.benefits);
                                    }
                                    if (element.custom.negotiable == 'yes') {
                                        setOfferYesNegotiable(true);
                                    } else {
                                        setOfferNoNegotiable(true);
                                    }
                                }
                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'offer_hiring_form_full_edit', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };

                            } else if (user_type == "contact_candidate_personally_details") {
                                arr = [{
                                    recipient_id: response.bot_response[0].recipient_id,
                                    text: response.bot_response[0].text
                                }]

                                if (element.custom) {
                                    setContactCandidateEmail(element.custom.contact);
                                    setContactCandidateNumber(element.custom.email);
                                }
                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'contact_candidate_personally_details', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };

                            } else if (user_type == "candidate_questions_forward") {

                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'candidate_questions_forward', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };

                            } else if (user_type == "offered_candidate_options") {

                                if (element.custom && element.custom.Title) {
                                    if (element.custom.Title != 'skip') {
                                        setRoleOfferInput(element.custom.Title);
                                    }
                                    if (element.custom.Monthly) {
                                        setMonthlyBasicForm(element.custom.Monthly);
                                    }
                                    if (element.custom.Annual != 'skip') {
                                        setAnnualWageForm(element.custom.Annual);
                                    }
                                    if (element.custom.Commission != 'skip') {
                                        setAdditionalWageComponent(element.custom.Commission);
                                    }
                                    if (element.custom.benefits != 'skip') {
                                        setAdditionalPrivilegesForm(element.custom.benefits);
                                    }
                                    if (element.custom.negotiable == 'yes') {
                                        setOfferYesNegotiable(true);
                                    } else {
                                        setOfferNoNegotiable(true);
                                    }

                                }
                                
                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'offered_candidate_options', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };
                                
                            } else if (user_type == "preferred_start_date_appointment") {
                                console.log(arr);
                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'preferred_start_date_appointment', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };

                                var dtToday = new Date();

                                var month = dtToday.getMonth() + 1;
                                var day = dtToday.getDate();
                                var year = dtToday.getFullYear();
                                if (month < 10)
                                    month = '0' + month.toString();
                                if (day < 10)
                                    day = '0' + day.toString();

                                var maxDate = year + '-' + month + '-' + day;
                                setMinDate(maxDate);

                            } else if(user_type == "application_completed_candidate") {
                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'application_completed_candidate', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };
                            } else if (user_type == "reason_list_candidate_dropdown") {

                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'reason_list_candidate_dropdown', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };

                            } else if (user_type == "expected_salary_candidate_input") {

                                if (element.custom && element.custom.Title) {
                                    if(element.custom.Title) {
                                        setRoleOfferInput(element.custom.Title);
                                    } else if(element.custom.average_salary) {
                                        setAdditionalWageComponent(element.custom.average_salary);
                                    } else if(element.custom.industry) {
                                        setInputCustomSection(element.custom.industry);
                                    }
                                }

                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'expected_salary_candidate_input', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };

                            } else if (user_type == "hiring_offer_accepted") {

                                if (element.custom && element.custom.Title) {
                                    if (element.custom.Title != 'skip') {
                                        setRoleOfferInput(element.custom.Title);
                                    }
                                    if (element.custom.Monthly) {
                                        setMonthlyBasicForm(element.custom.Monthly);
                                    }
                                    if (element.custom.Annual != 'skip') {
                                        setAnnualWageForm(element.custom.Annual);
                                    }
                                    if (element.custom.Commission != 'skip') {
                                        setAdditionalWageComponent(element.custom.Commission);
                                    }
                                    if (element.custom.benefits != 'skip') {
                                        setAdditionalPrivilegesForm(element.custom.benefits);
                                    }
                                    if (element.custom.negotiable == 'yes') {
                                        setOfferYesNegotiable(true);
                                    } else {
                                        setOfferNoNegotiable(true);
                                    }

                                }

                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'hiring_offer_accepted', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };

                            } else if(user_type == "hiring_no_continue_feedback") {
                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'hiring_no_continue_feedback', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };
                            } else if(user_type == "approach_hiring_negotiate_offer") {
                                
                                if (element.custom && element.custom.Title) {
                                    if (element.custom.Title != 'skip') {
                                        setRoleOfferInput(element.custom.Title);
                                    }
                                    if (element.custom.Monthly) {
                                        setMonthlyBasicForm(element.custom.Monthly);
                                    }
                                    if (element.custom.Annual != 'skip') {
                                        setAnnualWageForm(element.custom.Annual);
                                    }
                                    if (element.custom.Commission != 'skip') {
                                        setAdditionalWageComponent(element.custom.Commission);
                                    }
                                    if (element.custom.benefits != 'skip') {
                                        setAdditionalPrivilegesForm(element.custom.benefits);
                                    }
                                    if (element.custom.negotiable == 'yes') {
                                        setOfferYesNegotiable(true);
                                    } else {
                                        setOfferNoNegotiable(true);
                                    }
                                }

                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'approach_hiring_negotiate_offer', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };
                            } else if(user_type == "candidate_personal_details") {
                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'candidate_personal_details', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };
                            } else {
                                response_temp = { sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'text', msg: arr, buttons: [], text_type: user_type, checkEnable: false, datetime: moment().format("HH:mm") };
                            }
                        })

                        if (custom_candidates && custom_candidates.length > 0) {
                            setCustomCandidates(custom_candidates);

                            //console.log(customCandidates);
                            // customCandidates.length > 0 && customCandidates.map((data, index) => {
                            //     if(data.profile_pic != "") {
                            //         fetch(`${data.profile_pic}`, {
                            //             method: 'GET',
                            //             headers: {
                            //                 'Content-Type': 'application/json'
                            //             }
                            //         })
                            //         .then((response) => response.json())
                            //         .then((response) => {
                            //             console.log(response);
                            //             data.image = response.payload;
                            //         })
                            //         .catch((error) => {
                            //             console.error('Error:', error);
                            //         });
                            //     }
                            // })

                        }

                        if (response_temp.sender) {
                            if (user_type == 'average_salary_prediction') {
                                let arr2 = (response_temp.msg).pop();

                                setJobTitleBackend(arr2.text);

                                if (arr2) {
                                    let arr3 = (response_temp.msg).pop();
                                    setChat(chat => [...chat, response_temp]);

                                    user_type = "average_salary_prediction_offer_form";
                                    response_temp1 = {
                                        sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'average_salary_prediction_offer_form', msg: [{
                                            "recipient_id": arr3.recipient_id,
                                            "text": arr3.text
                                        }], buttons: [], text_type: "average_salary_prediction_offer_form", checkEnable: false, datetime: moment().format("HH:mm")
                                    };

                                    setChat(chat => [...chat, response_temp1]);
                                }

                            } else if(user_type == "expected_salary_candidate_input") {
                                let arr2 = (response_temp.msg).pop();
                                
                                setChat(chat => [...chat, response_temp]);
                                if(arr2) {
                                    console.log(response_temp);
                                    console.log(arr2);
                                    let response_temp1 = {
                                        sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'expected_salary_candidate_input_show', msg: [{
                                            "recipient_id": arr2.recipient_id,
                                            "text": arr2.text
                                        }], buttons: [], text_type: "expected_salary_candidate_input_show", checkEnable: false, datetime: moment().format("HH:mm")
                                    };
                                    setChat(chat => [...chat, response_temp1]);
                                }

                            } else if(user_type == "hiring_offer_accepted") {
                                let arr1 = (response_temp.msg).pop();
                                let arr2 = (response_temp.msg).pop();
                                let arr3 = (response_temp.msg).pop();
                                let arr4 = (response_temp.msg).pop();

                                setChat(chat => [...chat, response_temp]);

                                let otherclub = [arr4, arr3];
                                setInputCustomSection(otherclub);

                                let response_temp1 = {
                                    sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'hiring_offer_accepted_contactdetails', msg: otherclub, buttons: [], text_type: "hiring_offer_accepted_contactdetails", checkEnable: false, datetime: moment().format("HH:mm")
                                };
                                setChat(chat => [...chat, response_temp1]);

                                if(response_temp1) {
                                    let club = [arr2, arr1];
                                    setOfferAcceptedButtons(club);

                                    let response_temp2 = {
                                        sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'hiring_offer_accepted_buttons', msg: club, buttons: [], text_type: "hiring_offer_accepted_buttons", checkEnable: false, datetime: moment().format("HH:mm")
                                    };
                                    setChat(chat => [...chat, response_temp2]);

                                }

                            } else if(user_type == "approach_hiring_negotiate_offer") {
                                let arr1 = (response_temp.msg).pop();
                                let arr2 = (response_temp.msg).pop();
                                let arr3 = (response_temp.msg).pop();
                                let arr4 = (response_temp.msg).pop();

                                setChat(chat => [...chat, response_temp]);

                                let otherclub = [arr4, arr3];
                                setInputCustomSection(otherclub);

                                let club = [arr2, arr1];
                                setOfferAcceptedButtons(club);

                                let response_temp1 = {
                                    sender: "bot", recipient_id: response.bot_response[0].recipient_id, type: 'approach_hiring_negotiate_offer_buttons', msg: club, buttons: [], text_type: "approach_hiring_negotiate_offer_buttons", checkEnable: false, datetime: moment().format("HH:mm")
                                };
                                setChat(chat => [...chat, response_temp1]);
                        
                            } else {
                                setChat(chat => [...chat, response_temp]);
                            }
                        }

                        if (paramvalue.id == '1' && user_type == 'job_position') {
                            setJob(true);

                            setUserType(userType => [...userType, user_type]);
                        }

                        setTimeout(
                            () => setJustNow(false), 2000
                        );

                    }
                }
            })
    }

    const addNewQuestions = () => {
        let arr = {
            recipient_id: questionsHiring[0].recipient_id,
            text: '',
            name: `Question${questionsHiring.length + 1}`,
            value: '',
            yesclass: 'button',
            noclass: 'button'
        }

        setQuestionsHiring(questionsHiring => [...questionsHiring, arr]);
    }

    const changeHandler = (event) => {
        var file_list = event.target.files;
        if (event.target.files && event.target.files.length > 0) {
            setIsSelected(true);
            setSelectedFile(event.target.files[0]);

            for (var i = 0, file; file = file_list[i]; i++) {

                var sFileName = file.name;
                var sFileExtension = sFileName.split('.')[sFileName.split('.').length - 1].toLowerCase();
                var iFileSize = file.size;
                var iConvert = (file.size / 1048576).toFixed(2);

                if (!(sFileExtension === "pdf" ||
                    sFileExtension === "doc" ||
                    sFileExtension === "docx") || iFileSize > 10485760) { /// 10 mb
                    let txt = "File type : " + sFileExtension + "\n\n";
                    txt += "Size: " + iConvert + " MB \n\n";
                    txt += "Please make sure your file is in pdf or doc format and less than 10 MB.\n\n";
                    //  alert(txt);
                    setIsAccepted(false);

                } else {
                    setIsAccepted(true);
                }
            }
        }

    };

    const handleSubmission = async function handleFile() {
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('title', getJDTitle);

        await fetch(`${dev_base_url}/bot/upload-jd`, {
            method: 'POST',
            body: formData,
        }
        )
            .then((response) => response.json())
            .then((response) => {
                if (response.status) {
                    var msg = [{
                        recipient_id: paramvalue.id == '1' ? hiringname : candname,
                        text: `File ${selectedFile.name} uploaded successfully`
                    }]
                    const request_temp = { sender: "user", sender_id: paramvalue.id == '1' ? hiringname : candname, recipient_id: paramvalue.id == '1' ? hiringname : candname, type: 'text', msg: msg, buttons: [], text_type: "text", checkEnable: false, datetime: moment().format("HH:mm") };

                    setChat(chat => [...chat, request_temp]);
                    setbotTyping(true);
                    //    setIsSelected(false);

                    rasaAPI(paramvalue.id == '1' ? hiringname : candname, response.status);

                    let views = chat.slice();
                    for (let i in views) {
                        if (views[i].sender == 'bot' && views[i].type == 'upload') {
                            views[i].checkEnable = true;
                            setChat(views);
                            break;
                        }
                    }

                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const getJobTitles = async function getJobDesc(job) {

        await fetch(`${dev_base_url}/bot/job-titles`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ "req_title": job })
        })
            .then((response) => response.json())
            .then((response) => {
                setJob(false);
                if (response.related_job_titles && response.related_job_titles.length > 0) {
                    setJobDescArray(response.related_job_titles);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const getJD = async function getJobDescription(job) {

        await fetch(`${dev_base_url}/bot/job-description`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ "selected_title": job })
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(response);
                setJobDesc(response.jd[0]);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const handleJobTextChange = (event) => {
        getJobDesc(event.target.value);
    };

    const handleRequirementTextChange = (event) => {
        setOtherRequirements(event.target.value);
    }

    const handleSalaryChange = (e) => {
        if (e.target.value >= 0) {
            setSalaryCompensation(e.target.value);
        } else {
            toast('Please input valid number in the box to proceed.', {
                position: "top-right",
                autoClose: 3000,
                type: 'error',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
        }
    }

    const handleYearlySalaryChange = (e) => {
        if (e.target.value >= 0) {
            setYearlyCompensation(e.target.value);
        } else {
            toast('Please input valid number in the box to proceed.', {
                position: "top-right",
                autoClose: 3000,
                type: 'error',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
        }
    }

    const handleCommissionChange = (e) => {
        if (e.target.value >= 0) {
            setCommission(e.target.value);
        } else {
            toast('Please input valid number in the box to proceed.', {
                position: "top-right",
                autoClose: 3000,
                type: 'error',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
        }
    }

    const handleAdditionalCommissionChange = (e) => {
        setAdditionalCommission(e.target.value);
    }

    const handleBudgetChange = (e) => {
        if (e.target.value >= 0) {
            setBudget(e.target.value);
        } else {
            toast('Please input valid number in the box to proceed.', {
                position: "top-right",
                autoClose: 3000,
                type: 'error',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
        }
    }

    const handleOrganisationChange = (e) => {
        setOrganisation(e.target.value);
    }

    const handleExpectationsChange = (e) => {
        setJobExpectations(e.target.value);
    }

    const handleTeamSizeChange = (e) => {
        if (e.target.value >= 0) {
            setTeamSize(e.target.value);
        } else {
            toast('Please input valid number in the box to proceed.', {
                position: "top-right",
                autoClose: 3000,
                type: 'error',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
        }

    }

    const handleSupervisorChange = (e) => {
        setSupervisorInput(e.target.value);
    }

    const handleCompanyStructureChange = (e) => {
        setCompanyStructure(e.target.value);
    }

    const handleFirstQuestionChange = (event) => {
        let views = questionsHiring.slice();
        for (let i in views) {
            if (views[i].name == event.target.name) {
                views[i].value = event.target.value;
                setQuestionsHiring(views);
                break;
            }
        }
    }

    const handleNameRefereeChange = (e) => {
        if (/\d/.test(e.target.value)) {
            toast('Please input valid name in the box to proceed.', {
                position: "top-right",
                autoClose: 3000,
                type: 'error',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
        } else {
            setNameReferee(e.target.value);
        }

    }

    const handleEmailRefereeChange = (e) => {
        setEmailReferee(e.target.value);
    }

    const handleDateRange = (e) => {
        setDateSelection(e.target.value)
    }

    const handleTimeRange = (e) => {
        setTimeSelection(e.target.value);
    }

    const handleDate1Range = (e) => {
        setDateSelection1(e.target.value)
    }

    const handleTime1Range = (e) => {
        setTimeSelection1(e.target.value);
    }

    const handleDate2Range = (e) => {
        setDateSelection2(e.target.value)
    }

    const handleTime2Range = (e) => {
        setTimeSelection2(e.target.value);
    }

    const handleDate3Range = (e) => {
        setDateSelection3(e.target.value)
    }

    const handleTime3Range = (e) => {
        setTimeSelection3(e.target.value);
    }

    const handleFeedbackHrX = (e) => {
        setFeedbackXHours(e.target.value);
    }

    const handleCandidateQuestionsTextbox = (e) => {
        setCandidateQuestionsTextbox(e.target.value);
    }

    const handleHRProfileExperienceChange = (e) => {
        if (e.target.value >= 0) {
            setHrProfileExperience(e.target.value);
        } else {
            toast('Please input valid number in the box to proceed.', {
                position: "top-right",
                autoClose: 3000,
                type: 'error',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
        }
    }

    const handleRoleToOffer = (e) => {
        console.log(e.target.value);
        if (e.target.value == "roleoffer" && roleToOffer == true) {
            setRoleToOffer(!roleToOffer);
            setShowRoleToOfferInput(false);
        } else {
            setRoleToOffer(!roleToOffer);
            setShowRoleToOfferInput(true);
        }

    }

    const handleRoleOfferInput = (e) => {
        setRoleOfferInput(e.target.value);
    }

    const handleMonthlyBasicForm = (e) => {
        if (e.target.value >= 0) {
            setMonthlyBasicForm(e.target.value);
        } else {
            toast('Please input valid number in the box to proceed.', {
                position: "top-right",
                autoClose: 3000,
                type: 'error',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
        }
    }

    const handleAnnualWageForm = (e) => {
        if (e.target.value >= 0) {
            setAnnualWageForm(e.target.value);
        } else {
            toast('Please input valid number in the box to proceed.', {
                position: "top-right",
                autoClose: 3000,
                type: 'error',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
        }
    }

    const handleAdditionalWageComponent = (e) => {
        setAdditionalWageComponent(e.target.value);
    }

    const handleAdditionalPrivileges = (e) => {
        setAdditionalPrivilegesForm(e.target.value);
    }

    const handleFeedbackFromHiring = (e) => {
        setFeedbackFromHiring(e.target.value);
    }

    const handleYesOfferNegotiableForm = (e) => {
        if (e.target.value == 'yes') {
            if (offerYesNegotiableForm == true) {
                setOfferYesNegotiable(!offerYesNegotiableForm);
                setOfferNoNegotiable(true);
            } else {
                setOfferYesNegotiable(!offerYesNegotiableForm);
                setOfferNoNegotiable(false);
            }
        }
    }

    const handleNoOfferNegotiableForm = (e) => {
        if (e.target.value == 'no') {
            if (offerNoNegotiableForm == true) {
                setOfferNoNegotiable(!offerNoNegotiableForm);
                setOfferYesNegotiable(true);
            } else {
                setOfferNoNegotiable(!offerNoNegotiableForm);
                setOfferYesNegotiable(false);
            }
        }
    }

    const handleExpectedSalaryChange = (e) => {
        setExpectedSalary(e.target.value);
    }

    const hiringQuestionAnswer = (data, bit) => {
        if (bit == 10) {
            let split = data.split("_");
            let views = questionsHiring.slice();
            for (let i in views) {
                if (views[i].value == split[1]) {
                    if (split[0] == '/affirm') {
                        views[i].yesclass = 'button highlight';
                        views[i].noclass = "button";
                    } else {
                        views[i].noclass = 'button highlight';
                        views[i].yesclass = "button";
                    }
                    setQuestionsHiring(views);
                    break;
                }
            }
            //  setValueAnswerSelected(data);
        } else if (bit == 11) {
            let split = data.split("_");
            let views = candidateQuestionsHiring.slice();
            for (let i in views) {
                if (views[i].text == split[1]) {
                    if (split[0] == '/affirm') {
                        views[i].yesclass = 'button highlight';
                        views[i].noclass = "button";
                    } else {
                        views[i].noclass = 'button highlight';
                        views[i].yesclass = "button";
                    }
                    setCandidateQuestionsHiring(views);
                    break;
                }
            }
        }
    }

    const continueMonthly = (monthly, yearly) => {
        var newmsg = [{
            recipient_id: paramvalue.id == '1' ? hiringname : candname,
            text: `Monthly Salary SGD ${monthly} Yearly Salary SGD ${yearly}`
        }]

        const request_temp = { sender: "user", sender_id: paramvalue.id == '1' ? hiringname : candname, recipient_id: paramvalue.id == '1' ? hiringname : candname, type: 'text', msg: newmsg, buttons: [], text_type: "text", checkEnable: false, datetime: moment().format("HH:mm") };

        setChat(chat => [...chat, request_temp]);

        setbotTyping(true);

        rasaAPI(paramvalue.id == '1' ? hiringname : candname, `Monthly Salary SGD ${monthly} Yearly Salary SGD ${yearly}`);

        let views = chat.slice();
        for (let i in views) {
            if (views[i].sender == 'bot' && views[i].type == 'monthly_compensation') {
                views[i].checkEnable = true;
                setChat(views);
                break;
            }
        }
    }

    const submitNewJD = async function submitUpdatedJd(jd) {
        await fetch(`${dev_base_url}/bot/save-updated-jd`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ "title": getJDTitle, "ujd": jd })
        })
            .then((response) => response.json())
            .then((response) => {
                const request_temp = { sender: "user", sender_id: paramvalue.id == '1' ? hiringname : candname, msg: [{ recipient_id: paramvalue.id == '1' ? hiringname : candname, text: "Job description has been submitted successfully" }], type: 'text', buttons: [], text_type: "text", checkEnable: false, datetime: moment().format("HH:mm") };
                setChat(chat => [...chat, request_temp]);
                setbotTyping(true);
                rasaAPI(paramvalue.id == '1' ? hiringname : candname, response.status);

                let views = chat.slice();
                for (let i in views) {
                    if (views[i].sender == 'bot' && views[i].type == 'jd_load') {
                        views[i].checkEnable = true;
                        setChat(views);
                        break;
                    }
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });

    }

    return (
        <div>
            <section className="banner_section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="banner_inner">
                                <i className="far fa-angle-left" onClick={() => navigate(-1)}></i>
                                <img src={avtar} />
                                <div className="banner_right">
                                    <div className="title">Jarvis</div>
                                    {botTyping ?
                                        (<div className="online_title">
                                            Online<span></span>
                                        </div>) :
                                        (<div className="online_title">
                                            Bot Typing.... <span></span>
                                        </div>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="chat_outer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="chat_upper" id="messageArea">
                                {chat.map((user, key) => (
                                    <div key={key}>
                                        {user.sender === 'bot' ?
                                            (
                                                <div className="chat_left">
                                                    <div className="chat_left_inner">
                                                        {botTyping ?
                                                            (<div className="main_img"><img className="img_chat" src={avtar} /><div className="chat_left_inner1 specing_bottom width_custom">
                                                                <div className="chat_person_name jasviwr5">
                                                                    Jarvis
                                                                </div>

                                                                {user.type == 'text' &&
                                                                    user.msg && user.msg.map((message, index) => {
                                                                        return <p key={index}>{message.text}</p>
                                                                    })}

                                                                {user.type == 'initial_image' && <img className="loader_img" src={dotted} />}

                                                                {user.type == 'text_button' &&
                                                                    user.msg && user.msg.map((message, index) => {
                                                                        return <p key={index}>{message.text}</p>
                                                                    })}

                                                                {user.type == 'text_button' &&
                                                                    user.buttons && user.buttons.map((con, index) => {
                                                                        // if (user.checkEnable == false) {
                                                                        return <div style={user.checkEnable == true ? { pointerEvents: "none", opacity: "0.4" } : {}} key={index}><button type="button" className='chat_a' onClick={() => { onButtonSelect(con, 'text_button') }}>{con.title}</button></div>
                                                                        // } else {
                                                                        //     return <button type="button" disabled="true" className='chat_a'>{con.title}</button>
                                                                        // }
                                                                    })
                                                                }

                                                                {user.type == 'upload' && <p>{user.msg[0].text}</p>}
                                                                {user.type == 'upload' && <div className="drag_select" style={user.checkEnable == true ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                                                    <div id="id9" className="custom_id8">
                                                                        <input id="yourinputname" type="file" name="file" accept=".doc,.docx,.pdf" onChange={changeHandler} />

                                                                        <img src={shape} />
                                                                        <h5>Drag and drop here</h5>
                                                                        <h5>Or</h5>
                                                                        <h4>Select File</h4>
                                                                        <p className="only_word4">Only Word and PDF files with maximum size 10mb.</p>
                                                                    </div>

                                                                    {isSelected && selectedFile.name ? (<div className="jon_img">
                                                                        <p>
                                                                            {isFileAccepted && <img className="check_inner" src={check} />}
                                                                            {!isFileAccepted && <img className="check_inner" src={close} />}

                                                                            <img src={small_doc} />
                                                                            <span className="jpny7">{selectedFile.name}</span><span className="mb">{(selectedFile.size / 1048576).toFixed(2)}mb</span>
                                                                        </p>
                                                                        {!isFileAccepted && <p className="color_red">It seems like you've uploaded an unaccepted file.</p>}
                                                                        {!isFileAccepted && <p className="color_red">Please make sure that your file is in Word or PDF format, and is under 10mb in size.</p>}
                                                                    </div>) : (<></>)}
                                                                    {isFileAccepted && <div className="btn_ok">
                                                                        <button className="chat_a" type="button" onClick={handleSubmission}>Ok. I’m done</button>
                                                                    </div>}
                                                                </div>}

                                                                {user.text_type == 'jd_title' &&
                                                                    <div className="inner_mar6">
                                                                        <select onChange={onSelectchange}>
                                                                            <option value=''>Select Template</option>
                                                                            {
                                                                                jobDescArray && jobDescArray.map((con, index) => {
                                                                                    return <option key={`${index}`} value={con}>{con}</option>
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>}

                                                                {user.type == 'jd_load' && <div className="jon_de4" style={user.checkEnable == true ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                                                    <h4>{getJDTitle}</h4>
                                                                    <h5>Job Description</h5>
                                                                    <div className="bor56">
                                                                        <textarea name="jd_textarea" value={getJobDesc} onChange={handleJobTextChange} />
                                                                    </div>
                                                                    <div className="btn_ok"><button className="chat_a" type="button" onClick={() => { submitNewJD(getJobDesc) }}>Ok. I’m done</button></div>
                                                                </div>}

                                                                {user.type == 'other_requirements' && <div className="jon_de4" style={user.checkEnable == true ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                                                    <p>{user.msg[0].text}</p>
                                                                    <div className="bor56">
                                                                        <textarea name="requirement_textarea" value={getRequirements} onChange={handleRequirementTextChange} />
                                                                    </div>
                                                                    <div className="btn_ok"><button className="chat_a" type="button" onClick={() => { submitData(getRequirements, 0, 'other_requirements') }}>Ok. I’m done</button></div>
                                                                </div>}

                                                                {user.type == 'monthly_compensation' && <div className="dhs_step1" style={user.checkEnable == true ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                                                    <p>What is your planned basic salary for this role? Kindly indicate both the monthly compensation and the total yearly package. Please only enter numbers in the boxes provided.</p>
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <div className="slar_box">
                                                                                <h5>Monthly Salary</h5>
                                                                                <div className='input67_in'>
                                                                                    <p>SGD</p>
                                                                                    <input type="number" name="salary" value={salaryCompensation} onChange={handleSalaryChange}></input>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="slar_box">
                                                                                <h5>Annual Salary</h5>
                                                                                <div className='input67_in'>
                                                                                    <p>SGD</p>
                                                                                    <input type="number" value={yearlySalaryCompensation} onChange={handleYearlySalaryChange}></input>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="btn_ok"><button className="chat_a" type="button" onClick={() => { continueMonthly(salaryCompensation, yearlySalaryCompensation) }}>Ok. I’m done</button></div>
                                                                </div>}

                                                                {user.type == 'incentive' && <div className="dhs_step2" style={user.checkEnable == true ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                                                    <p>{user.msg[0].text}</p>
                                                                    <div className="commis_outer">
                                                                        <h5>Commission (If Any)</h5>
                                                                        <input type="number" name="commission" value={commission} onChange={handleCommissionChange}></input>
                                                                    </div>
                                                                    <div className="commis_outer comis_text6">
                                                                        <h5>Additional Privileges and Benefits (If Any)</h5>
                                                                        <textarea name="commission_additional" value={getAdditionalCommission} onChange={handleAdditionalCommissionChange} />
                                                                    </div>
                                                                    <div className='skip_outer45'>
                                                                        <div className="btn_ok skip_inner"><button className="chat_a" type="button" onClick={() => { submitData("skip", "skip") }}>Skip</button></div>
                                                                        <div className="btn_ok"><button className="chat_a" type="button" onClick={() => { submitData(commission, 1, 'incentive') }}>Ok. I’m done</button></div>
                                                                    </div>
                                                                </div>}

                                                                {user.type == 'budget_package' && <div className="dhs_step2 dhstep3" style={user.checkEnable == true ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                                                    <p>{user.msg[0].text}</p>
                                                                    <div className="iner_inp89">
                                                                        <span className="plus45">±</span><input type="number" name="budget" value={budget} onChange={handleBudgetChange} /><span>%</span>
                                                                    </div>
                                                                    <div className="btn_ok"><button className="chat_a" type="button" onClick={() => { submitData(budget, 2, 'budget_package') }}>Ok. I’m done</button></div>
                                                                </div>}

                                                                {user.type == 'specific_organisation' && <div className="jon_de4" style={user.checkEnable == true ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                                                    <p>{user.msg[0].text}</p>
                                                                    <h5>Organisation name(s)</h5>
                                                                    <div className="bor56">
                                                                        <textarea name="specific_organisation" value={getOrganisation} onChange={handleOrganisationChange} />
                                                                    </div>
                                                                    <div className='skip_outer45'>
                                                                        <div className="btn_ok skip_inner"><button className="chat_a" type="button" onClick={() => { submitData("skip company", "skip", 'specific_organisation') }}>Skip</button></div>
                                                                        <div className="btn_ok"><button className="chat_a" type="button" onClick={() => { submitData(getOrganisation, 3, 'specific_organisation') }}>Ok. I’m done</button></div>
                                                                    </div>
                                                                </div>}

                                                                {user.type == 'job_expectations' && <div className="dhs_step2 dht_step5" style={user.checkEnable == true ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                                                    <p>Please provide the job-related expectations for this candidate. These may include late shifts, travel, work on weekends, language proficiency, experience in senior stakeholders, personal strengths, etc.</p>

                                                                    <div className="commis_outer comis_text6">
                                                                        <h5>Expectations for this position (If not already included in the Job Description)</h5>
                                                                        <textarea name="job_expectations" value={jobExpectations} onChange={handleExpectationsChange} />
                                                                    </div>
                                                                    <div className='skip_outer45'>
                                                                        <div className="btn_ok skip_inner"><button className="chat_a" type="button" onClick={() => { submitData("skip", "skip", 'job_expectations') }}>Skip</button></div>
                                                                        <div className="btn_ok"><button className="chat_a" type="button" onClick={() => { submitData(jobExpectations, 4, 'job_expectations') }}>Ok. I’m done</button></div>
                                                                    </div>
                                                                </div>}

                                                                {user.type == 'team_size' && <div className="dhs_step1 dhs_step6" style={user.checkEnable == true ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                                                    <p>What is the size of the team that the successful candidate will be joining?</p>
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className="slar_box">
                                                                                <h5>Please enter number of employees in your team:</h5>
                                                                                <div className='input67_in'>

                                                                                    <input type="number" name="teamsize" value={teamSize} onChange={handleTeamSizeChange}></input>
                                                                                    <p className="empli8">employees</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <div className='skip_outer45'>
                                                                        <div className="btn_ok skip_inner"><button className="chat_a" type="button" onClick={() => { submitData("Skip Employees", "skip", 'team_size') }}>Skip</button></div>
                                                                        <div className="btn_ok"><button className="chat_a" type="button" onClick={() => { submitData(teamSize, 5, 'team_size') }}>Ok. I’m done</button></div>
                                                                    </div>
                                                                </div>}

                                                                {user.type == 'replacement_opening' && <div className="dhs_step2 dhstep4" style={user.checkEnable == true ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                                                    <p>Is this opening intended for replacement or an added headcount in your company? *</p>
                                                                    <div className="bth_bt78">
                                                                        <div className="btn_ok"><button className="chat_a" type="button" onClick={() => { submitData("/deny", 6, 'replacement_opening') }}>Staff Replacement</button> <button className="chat_a" type="button" onClick={() => { submitData("/affirm", 6, 'replacement_opening') }}>Added Headcount</button></div>
                                                                        <div className="btn_ok righ79"><button className="chat_a" type="button" onClick={() => { submitData("/skip", "skip", 'replacement_opening') }}>Skip</button></div>
                                                                    </div>
                                                                    <p className="cusy890">*This information will be used for candidate reference. Hiring for a replacement could indicate that the role may need to be filled at shorter notice, especially for termination cases and voluntary/involuntary resignations. On the other hand, an added headcount usually implies that the business is expanding, and there is ample time to pool more candidates.
                                                                    </p>
                                                                </div>}

                                                                {user.type == 'conributor_role' && <div className="dhs_step2 dhstep4" style={user.checkEnable == true ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                                                    <p>{user.msg[0].text}</p>
                                                                    <div className="bth_bt78">
                                                                        <div className="btn_ok"><button className="chat_a" type="button" onClick={() => { submitData("/affirm", 7, 'conributor_role') }}>Team Member</button> <button className="chat_a" type="button" onClick={() => { submitData("/deny", 7, 'conributor_role') }}>Management</button></div>
                                                                        <div className="btn_ok righ79"><button className="chat_a" type="button" onClick={() => { submitData("/skip", "skip", 'conributor_role') }}>Skip</button></div>
                                                                    </div>
                                                                </div>}

                                                                {user.type == 'stakeholder_role' && <div className="dhs_step2 dhs_step7" style={user.checkEnable == true ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                                                    <p>If successfully hired, who will this candidate be reporting to?</p>
                                                                    <div className="commis_outer step89">
                                                                        <h5>Position of Stakeholder or Supervisor:</h5>
                                                                        <input type="text" name="supervisor_input" value={supervisorInput} onChange={handleSupervisorChange}></input>
                                                                    </div>

                                                                    <div className='skip_outer45'>
                                                                        <div className="btn_ok skip_inner"><button className="chat_a" type="button" onClick={() => { submitData("skip", "skip", 'stakeholder_role') }}>Skip</button></div>
                                                                        <div className="btn_ok"><button className="chat_a" type="button" onClick={() => { submitData(supervisorInput, 8, 'stakeholder_role') }}>Ok. I’m done</button></div>
                                                                    </div>
                                                                </div>}

                                                                {user.type == 'company_structure' && <div className="dhs_step2 dht_step5" style={user.checkEnable == true ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                                                    <p>{user.msg[0].text}</p>
                                                                    <div className="commis_outer comis_text6">
                                                                        <textarea name="job_expectations" value={companyStructure} onChange={handleCompanyStructureChange} />
                                                                    </div>
                                                                    <div className='skip_outer45'>
                                                                        <div className="btn_ok skip_inner"><button className="chat_a" type="button" onClick={() => { submitData("skip", "skip", 'company_structure') }}>Skip</button></div>
                                                                        <div className="btn_ok"><button className="chat_a" type="button" onClick={() => { submitData(companyStructure, 9, 'company_structure') }}>Ok. I’m done</button></div>
                                                                    </div>
                                                                </div>}
                                                                {user.type == 'hiring_specific_questions' && <div className="dhs_step1 dhs_step8" style={user.checkEnable == true ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                                                    <p>Please provide the questions you might have for potential candidates, and indicate whether the desired answer is ‘Yes’ or ‘No’. We will score candidates by the percentage of answers they provide which match your desired answers. </p>
                                                                    <div className='hiring-row-questions'>
                                                                        {questionsHiring.length > 0 && questionsHiring.map((message, index) => {
                                                                            return (<div className="row" id="addQues">
                                                                                <div className="col-md-7">
                                                                                    <div className="commis_outer"><h5>Question {index + 1}:</h5><input type="text" name={`${message.name}`} value={`${message.value}`} onChange={handleFirstQuestionChange} /></div>
                                                                                </div>
                                                                                <div className="col-md-5">
                                                                                    <div className="commis_outer yes_outer56"><h5>Choose desired answer:</h5>
                                                                                        <div className="yes_ouy67">
                                                                                            <button type="button" className={`${message.yesclass}`} value={`/affirm_${message.value}`} onClick={() => { hiringQuestionAnswer(`/affirm_${message.value}`, 10) }}>Yes</button>
                                                                                            <button type="button" className={`${message.noclass}`} value={`/deny_${message.value}`} onClick={() => { hiringQuestionAnswer(`/deny_${message.value}`, 10) }}>No</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>)
                                                                        })}
                                                                    </div>

                                                                    <div className="skip_outer45 thr_btn">
                                                                        <div className="left67"><div className="btn_ok"><button className="chat_a" onClick={addNewQuestions} type="button">+ Add a new Question</button></div></div>
                                                                        <div className="btn_ok skip_inner"><button className="chat_a" type="button">Skip</button></div>
                                                                        <div className="btn_ok"><button className="chat_a" type="button" onClick={() => { submitData(questionsHiring, 10, 'hiring_specific_questions') }}>Ok. I’m done</button></div></div>
                                                                </div>}

                                                                {/* /////////// STep 4 + 5a ////////////// */}
                                                                {user.type == 'job_interview_book_hr' && <div className="dhs_step1 step5" style={user.checkEnable == true ? { pointerEvents: "none", opacity: "0.4" } : {}}>

                                                                    {user.msg && user.msg.map((message, index) => {
                                                                        return (<div>
                                                                            {message.text && <p>{message.text}</p>}
                                                                            {message.custom &&
                                                                                <div className="select_outer">
                                                                                    <select id="cars" onChange={event => onJobSelectchange(event, 'joblevel_before_offer_profile')}>
                                                                                        <option value="">Select Job</option>
                                                                                        {message.custom.data.map((data, ind) => {
                                                                                            return (
                                                                                                <option key={`${ind}`} value={`${data.value}_${data.label}`}>{data.label}</option>)
                                                                                        })}
                                                                                    </select>
                                                                                </div>
                                                                            }

                                                                        </div>)
                                                                    })}
                                                                </div>}

                                                                {user.type == 'view_profile_candidates_interview_book' && <div className="dhs_step1 show1" style={user.checkEnable == true ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                                                    {user.msg && user.msg.map((message, index) => {
                                                                        return (
                                                                            message.text && <p>{message.text}</p>
                                                                        )
                                                                    })}
                                                                    {(customCandidates && customCandidates.length > 0) &&
                                                                        <div className="owl_caur">
                                                                            <div className="row">
                                                                                <Carousel show={3} slide={1} swiping={true} responsive={true}>
                                                                                    {customCandidates && customCandidates.map((data, ind) => {
                                                                                        return (<div className="candidate_outer">
                                                                                            <img src={data.image ? data.image : c1} />
                                                                                            <div className="candidate_name" data-tip={data.first_name + ` ` + data.last_name}>{data.first_name.substring(0, 5)} {data.last_name.substring(0, 2)}..</div>
                                                                                            <a className="view_profile" >View Profile</a>
                                                                                            <button className="view_profile select_candidate" onClick={() => { candidateSelectnterview(data, 'view_profile_candidates_interview_book') }} >Select Candidate</button>
                                                                                        </div>)
                                                                                    })}
                                                                                </Carousel>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>}

                                                                {user.type == 'date_time_picker_book_interview_hr' && <div className="dhs_step1 show1" style={user.checkEnable == true ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                                                    <p>{user.msg[0].text}</p>

                                                                    <div className="select_field custom_field12">
                                                                        <div className="row">
                                                                            <div className="col-md-6">
                                                                                <div className="custom_input_field">
                                                                                    <label>Select a date</label>
                                                                                    {timeSlots == 1 && <input type="date" name="dateSelect" value={dateSelection} onChange={handleDateRange} min={minDate} />}
                                                                                    {timeSlots == 2 && <input type="date" name="dateSelect" value={dateSelection1} onChange={handleDate1Range} min={minDate} />}
                                                                                    {timeSlots == 3 && <input type="date" name="dateSelect" value={dateSelection2} onChange={handleDate2Range} min={minDate} />}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="custom_input_field">
                                                                                    <label>Select a time</label>
                                                                                    {timeSlots == 1 && <input type="time" id="appt" name="timeSelect" value={timeSelection} onChange={handleTimeRange} />}
                                                                                    {timeSlots == 2 && <input type="time" id="appt" name="timeSelect" value={timeSelection1} onChange={handleTime1Range} />}
                                                                                    {timeSlots == 3 && <input type="time" id="appt" name="timeSelect" value={timeSelection2} onChange={handleTime2Range} />}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-12 done_outer">
                                                                                {timeSlots == 1 && <button className="chat_a" onClick={() => { submitData(dateSelection, 13, 'date_time_picker_book_interview_hr') }}>Ok. I'm done</button>}
                                                                                {timeSlots == 2 && <button className="chat_a" onClick={() => { submitData(dateSelection1, 13, 'date_time_picker_book_interview_hr') }}>Ok. I'm done</button>}
                                                                                {timeSlots == 3 && <button className="chat_a" onClick={() => { submitData(dateSelection2, 13, 'date_time_picker_book_interview_hr') }}>Ok. I'm done</button>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>}

                                                                {user.type == 'candidate_interview_invitation' && <div className="dhs_step1 step5_c">
                                                                    {user.msg && user.msg.map((message, index) => {
                                                                        return (
                                                                            message.text && <p>{message.text}</p>
                                                                        )
                                                                    })}
                                                                    <div className="row">
                                                                        <div className="col-md-4">
                                                                            <div className="stepc_inner">
                                                                                <img src={step51} />
                                                                                <p>Practice Good
                                                                                    Nonverbal Communication</p>
                                                                            </div>

                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="stepc_inner">
                                                                                <img src={step52} />
                                                                                <p>Dress for the Job
                                                                                    or Company</p>
                                                                            </div>

                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="stepc_inner">
                                                                                <img src={step53} />
                                                                                <p>Take Care to Answer
                                                                                    the Questions</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>}

                                                                {user.type == 'candidate_interview_slots_datetime' && <div className="dhs_step1 step5_no" style={user.checkEnable == true ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                                                    <p>{user.msg[0].text}</p>
                                                                    <div className="row specr6">
                                                                        <div className="col-md-5">
                                                                            <div className="custom_input_field">
                                                                                <label>Select a date</label>
                                                                                <input type="date" name="dateSelect1" value={dateSelection1} onChange={handleDate1Range} min={minDate} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <div className="custom_input_field">
                                                                                <label>Select a time</label>
                                                                                <input type="time" name="timeSelect1" value={timeSelection1} onChange={handleTime1Range} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row specr6">
                                                                        <div className="col-md-5">
                                                                            <div className="custom_input_field">
                                                                                <label>Select a date</label>
                                                                                <input type="date" name="dateSelect2" value={dateSelection2} onChange={handleDate2Range} min={minDate} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <div className="custom_input_field">
                                                                                <label>Select a time</label>
                                                                                <input type="time" name="timeSelect2" value={timeSelection2} onChange={handleTime2Range} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row specr6">
                                                                        <div className="col-md-5">
                                                                            <div className="custom_input_field">
                                                                                <label>Select a date</label>
                                                                                <input type="date" name="dateSelect3" value={dateSelection3} onChange={handleDate3Range} min={minDate} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <div className="custom_input_field">
                                                                                <label>Select a time</label>
                                                                                <input type="time" name="timeSelect3" value={timeSelection3} onChange={handleTime3Range} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-12 done_outer">
                                                                            <button className="chat_a" onClick={() => { submitData(dateSelection1, 14, 'candidate_interview_slots_datetime') }}>Ok. I'm done</button>
                                                                        </div>
                                                                    </div>
                                                                </div>}

                                                                {user.type == 'hiring_candidate_confirm_slots_buttons' && <div className="dhs_step1 step5_no" style={user.checkEnable == true ? { pointerEvents: "none", opacity: "0.4" } : {}}>

                                                                    {user.msg && user.msg.map((message, index) => {
                                                                        return (<div>
                                                                            {message.text && <p>{message.text}</p>}
                                                                            {message.buttons && message.buttons.map((btn, ind) => {
                                                                                return (<div className="btn6_outer">
                                                                                    <button className="chat_a" onClick={() => { onButtonSelect(btn, 'hiring_candidate_confirm_slots_buttons') }}>{btn.title}</button>
                                                                                </div>)
                                                                            })}
                                                                        </div>
                                                                        )
                                                                    })}
                                                                </div>}

                                                                {user.type == 'x_hours_feedback_textarea' && <div className="jon_de4" style={user.checkEnable == true ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                                                    {user.msg && user.msg.map((message, index) => {
                                                                        return (
                                                                            message.text && <p>{message.text}</p>
                                                                        )
                                                                    })}
                                                                    <div className="bor56">
                                                                        <textarea name="hr_feed" value={feedback_hr_candidate_xhours} onChange={handleFeedbackHrX} />
                                                                    </div>
                                                                    <div className='skip_outer45'>
                                                                        <div className="btn_ok skip_inner"><button className="chat_a" type="button" onClick={() => { submitData("Skip", "skip", 'x_hours_feedback_textarea') }}>Skip</button></div>
                                                                        <div className="btn_ok"><button className="chat_a" type="button" onClick={() => { submitData(feedback_hr_candidate_xhours, 15, 'x_hours_feedback_textarea') }}>Ok. I’m done</button></div>
                                                                    </div>
                                                                </div>}

                                                                {user.type == 'candidate_questions_forward' && <div className="jon_de4" style={user.checkEnable == true ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                                                    {user.msg && user.msg.map((message, index) => {
                                                                        return (
                                                                            message.text && <p>{message.text}</p>
                                                                        )
                                                                    })}
                                                                    <div className="bor56">
                                                                        <textarea name="hr_feed" value={candidate_questions_textbox} onChange={handleCandidateQuestionsTextbox} />
                                                                    </div>
                                                                    <div className='skip_outer45'>
                                                                        <div className="btn_ok"><button className="chat_a" type="button" onClick={() => { submitData(candidate_questions_textbox, 18, 'candidate_questions_forward') }}>Ok. I’m done</button></div>
                                                                    </div>
                                                                </div>}

                                                                {user.type == 'reject_candidate_dropdown' && <div className="dhs_step1 step5" style={user.checkEnable == true ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                                                    {user.msg && user.msg.map((message, index) => {
                                                                        return (<div>
                                                                            {message.text && <p>{message.text}</p>}
                                                                            {message.custom &&
                                                                                <div className="select_outer">
                                                                                    <select id="cars" onChange={event => onJobSelectchange(event, 'reject_candidate_dropdown')}>
                                                                                        <option value="">Reason List</option>
                                                                                        {message.custom.data.map((data, ind) => {
                                                                                            return (
                                                                                                <option key={`${ind}`} value={`${data.value}_${data.label}`}>{data.label}</option>)
                                                                                        })}
                                                                                    </select>
                                                                                </div>
                                                                            }
                                                                        </div>)
                                                                    })}
                                                                </div>}

                                                                {user.type == 'experience_before_offer_profile' && <div className="dhs_step2" style={user.checkEnable == true ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                                                    {user.msg && user.msg.map((message, index) => {
                                                                        return (
                                                                            message.text && <p data-tip={message.text + `` + 'hi'}>{message.text}</p>
                                                                        )
                                                                    })}
                                                                    <div className="commis_outer">
                                                                        <h5>Experience</h5>
                                                                        <input type="number" name="hrProfileExperience" value={hrProfileExperience} onChange={handleHRProfileExperienceChange}></input>
                                                                    </div>

                                                                    <div className='skip_outer45'>
                                                                        <div className="btn_ok"><button className="chat_a" type="button" onClick={() => { submitData(hrProfileExperience, 16, 'experience_before_offer_profile') }}>Ok. I’m done</button></div>
                                                                    </div>
                                                                </div>}

                                                                {user.type == 'joblevel_before_offer_profile' && <div className="dhs_step1 step5" style={user.checkEnable == true ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                                                    {user.msg && user.msg.map((message, index) => {
                                                                        return (<div>
                                                                            {message.text && <p>{message.text}</p>}
                                                                            {message.custom &&
                                                                                <div className="select_outer">
                                                                                    <select id="cars" onChange={event => onJobSelectchange(event, 'joblevel_before_offer_profile')}>
                                                                                        <option value="">Job Level</option>
                                                                                        {message.custom.data.map((data, ind) => {
                                                                                            return (
                                                                                                <option key={`${ind}`} value={`${data.value}_${data.label}`}>{data.label}</option>)
                                                                                        })}
                                                                                    </select>
                                                                                </div>
                                                                            }
                                                                        </div>)
                                                                    })}
                                                                </div>}

                                                                {user.type == 'jobtype_before_offer_profile' && <div className="dhs_step1 step5" style={user.checkEnable == true ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                                                    {user.msg && user.msg.map((message, index) => {
                                                                        return (<div>
                                                                            {message.text && <p>{message.text}</p>}
                                                                            {message.custom &&
                                                                                <div className="select_outer">
                                                                                    <select id="cars" onChange={event => onJobSelectchange(event, 'jobtype_before_offer_profile')}>
                                                                                        <option value="">Job Type</option>
                                                                                        {message.custom.data.map((data, ind) => {
                                                                                            return (
                                                                                                <option key={`${ind}`} value={`${data.value}_${data.label}`}>{data.label}</option>)
                                                                                        })}
                                                                                    </select>
                                                                                </div>
                                                                            }
                                                                        </div>)
                                                                    })}
                                                                </div>}

                                                                {user.type == 'qualification_before_offer_profile' && <div className="dhs_step1 step5" style={user.checkEnable == true ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                                                    {user.msg && user.msg.map((message, index) => {
                                                                        return (<div>
                                                                            {message.text && <p>{message.text}</p>}
                                                                            {message.custom &&
                                                                                <div className="select_outer">
                                                                                    <select id="cars" onChange={event => onJobSelectchange(event, 'qualification_before_offer_profile')}>
                                                                                        <option value="">Select Qualification</option>
                                                                                        {message.custom.data.map((data, ind) => {
                                                                                            return (
                                                                                                <option key={`${ind}`} value={`${data.value}_${data.label}`}>{data.label}</option>)
                                                                                        })}
                                                                                    </select>
                                                                                </div>
                                                                            }
                                                                        </div>)
                                                                    })}
                                                                </div>}

                                                                {user.type == 'industrytype_before_offer_profile' && <div className="dhs_step1 step5" style={user.checkEnable == true ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                                                    {user.msg && user.msg.map((message, index) => {
                                                                        return (<div>
                                                                            {message.text && <p>{message.text}</p>}
                                                                            {message.custom &&
                                                                                <div className="select_outer">
                                                                                    <select id="cars" onChange={event => onJobSelectchange(event, 'industrytype_before_offer_profile')}>
                                                                                        <option value="">Industry Type</option>
                                                                                        {message.custom.data.map((data, ind) => {
                                                                                            return (
                                                                                                <option key={`${ind}`} value={`${data.value}_${data.label}`}>{data.label}</option>)
                                                                                        })}
                                                                                    </select>
                                                                                </div>
                                                                            }
                                                                        </div>)
                                                                    })}
                                                                </div>}

                                                                {user.type == 'average_salary_prediction' && <div className="dhs_step1 step72">
                                                                    <div className="main_outr5">
                                                                        {user.msg && user.msg.map((message, index) => {
                                                                            return (
                                                                                message.text && <p><b>{message.text}</b></p>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>}

                                                                {user.type == 'average_salary_prediction_offer_form' && <div className="dhs_step1 step73" style={user.checkEnable == true ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                                                    <p>{user.msg[0].text}</p>
                                                                    <div className="job_yut6">
                                                                        <h4>
                                                                            Job Title/Role
                                                                        </h4>
                                                                        <p>{jobTitleBackend}</p>
                                                                        <div className="sin_outer4">
                                                                            <div className="check_outer5">
                                                                                <label className="container99">I wish to offer a different role to the candidate.
                                                                                    <input type="checkbox" value="roleoffer" checked={roleToOffer} onChange={handleRoleToOffer} />
                                                                                    <span className="checkmark"></span>
                                                                                </label>
                                                                            </div>
                                                                            {showRoleToOfferInput == true && <div className="inner80">
                                                                                <h5>Role to be offered:</h5>
                                                                                <input type="text" placeholder="Sales and Marketing Manager" value={roleOfferInput} onChange={handleRoleOfferInput}></input>
                                                                            </div>}
                                                                        </div>
                                                                        <div className="monthly">
                                                                            <div className="slar_box"><h5>Monthly Basic Salary:</h5><div className="input67_in"><p>SGD</p><input type="number" placeholder='4500' value={monthlyBasicForm} onChange={handleMonthlyBasicForm} /><p className="pu8">per month</p></div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="monthly">
                                                                            <div className="slar_box"><h5>Annual Wage Supplement (if any)</h5><div className="input67_in"><p>SGD</p><input placeholder='1000' type="number" value={annualWageForm} onChange={handleAnnualWageForm} /><p className="pu8">per month </p></div></div>
                                                                        </div>
                                                                        <div className="commis_outer"><h5>Additional Wage Component/Commission (if any)</h5><input type="text" placeholder="10% commission on every sale over $100k annually" value={additionalWageComponentForm} onChange={handleAdditionalWageComponent} /></div>
                                                                        <div className="commis_outer comis_text6"><h5>Additional Privileges and Benefits (If Any)</h5>
                                                                            <textarea name="jd_textarea" value={additionalPrivilegesForm} onChange={handleAdditionalPrivileges} />
                                                                        </div>
                                                                        <div className="commis_outer comis_text6 yes_no"><h5>Is Your Offer Negotiable?</h5></div>
                                                                        <div className="check_outer5 custom_-ye5">
                                                                            <label className="container99">Yes
                                                                                <input type="checkbox" value="yes" checked={offerYesNegotiableForm} onChange={handleYesOfferNegotiableForm} />
                                                                                <span className="checkmark"></span>
                                                                            </label>
                                                                            <label className="container99 colr_change">No
                                                                                <input type="checkbox" value="no" checked={offerNoNegotiableForm} onChange={handleNoOfferNegotiableForm} />
                                                                                <span className="checkmark"></span>
                                                                            </label>
                                                                        </div>

                                                                        <div className='skip_outer45'>
                                                                            <div className="btn_ok skip_inner no_ho87"><button className="chat_a" type="button" onClick={() => { submitData("skip", "skip", 'average_salary_prediction_offer_form') }}>I prefer to talk to<br /> candidate personally</button></div>
                                                                            <div className="btn_ok no_ho879"><button className="chat_a" type="button" onClick={() => { submitData(monthlyBasicForm, 17, 'average_salary_prediction_offer_form') }}>Ok. I’m done</button></div>
                                                                        </div>
                                                                    </div>
                                                                </div>}

                                                                {user.type == 'offer_hiring_form_full_edit' && <div className="dhs_step1 step73" style={user.checkEnable == true ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                                                    <p>{user.msg[0].text}</p>
                                                                    <div className="main_outr5">
                                                                        <p><b>Job Title : </b>{roleOfferInput}</p>
                                                                        <p><b>Monthly Basic Salary : </b> SGD${monthlyBasicForm}</p>
                                                                        <p><b>Annual Wage Supplement : </b> SGD${annualWageForm}</p>
                                                                    </div>
                                                                    <div className="main_outr5">
                                                                        <p><b>Additional Wage Component/Commission: </b></p>
                                                                        <p>{additionalWageComponentForm}</p>
                                                                    </div>
                                                                    <div className="main_outr5">
                                                                        <p><b>Employee Benefits:</b></p>
                                                                        <p>{additionalPrivilegesForm}</p>
                                                                    </div>
                                                                    <div className="main_outr5">
                                                                        <p><b>Offer Negotiable?:</b> {offerYesNegotiableForm == true ? 'yes' : (offerNoNegotiableForm == true ? 'no' : '')}</p>
                                                                    </div>

                                                                    <div className="skip_outer45">
                                                                        <div className="btn_ok skip_inner"><button className="chat_a" type="button" onClick={() => { submitData("skip", "skip", 'offer_hiring_form_full_edit') }}>Edit</button></div>
                                                                        <div className="btn_ok"><button className="chat_a" type="button" onClick={() => { submitData(monthlyBasicForm, 17, 'offer_hiring_form_full_edit') }}>Confirm</button></div></div>
                                                                </div>}

                                                                {user.type == 'contact_candidate_personally_details' && <div className="dhs_step1 step73">
                                                                    <p>{user.msg[0].text}</p>
                                                                    <div className="inner560">
                                                                        <h4>Contact Number:</h4>
                                                                        <p>{contactCandidateNumber}</p>
                                                                    </div>
                                                                    <div className="inner560">
                                                                        <h4>Email:</h4>
                                                                        <p>{contactCandidateEmail}</p>
                                                                    </div>
                                                                </div>}

                                                                {user.type == 'hiring_offer_accepted' && 
                                                                <div className="dhs_step1 step72">
                                                                    <div className="main_outr5">
                                                                    {user.msg && user.msg.map((message, index) => {
                                                                        return (message.text && <p>{message.text}</p>
                                                                        )
                                                                    })}
                                                                        <p><b>Offered basic salary : </b>{monthlyBasicForm}</p>
                                                                        <p><b>Annual wage supplement : </b>{annualWageForm}</p>
                                                                        <p><b>Additional component : </b>{additionalPrivilegesForm}</p>
                                                                        <p><b>Job Title : </b>{roleOfferInput}</p>
                                                                        <p><b>Commission : </b>{additionalWageComponentForm}</p>
                                                                        <p><b>Negotiable? : </b>{offerYesNegotiableForm == "yes" ? "Yes" : "No"}</p>
                                                                    </div>
                                                                </div>}

                                                                {user.type == 'hiring_offer_accepted_contactdetails' && <div className="dhs_step1 step73">
                                                                    {inputCustomSection.map((message, index) => {
                                                                        return (
                                                                            <div>
                                                                                {message.text && <p>{message.text}</p>}
                                                                                {message.custom && <div className="inner560">
                                                                                    <h4>Contact Number:</h4>
                                                                                    <p>{message.custom.contact}</p>
                                                                                </div>}
                                                                                {message.custom && <div className="inner560">
                                                                                    <h4>Email:</h4>
                                                                                    <p>{message.custom.email}</p>
                                                                                </div>}
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>}

                                                                {user.type == 'hiring_offer_accepted_buttons' && <div className="dhs_step1 step73" style={user.checkEnable == true ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                                                    {offerAcceptedButtons.map((message, index) => {
                                                                        return (
                                                                            <div>
                                                                                {message.text && <p>{message.text}</p>}
                                                                                {message.buttons && <div className='skip_outer45'>
                                                                                    <div className="btn_ok">
                                                                                        <button className="chat_a" type="button" onClick={() => { onButtonSelect(message.buttons[0], 'text_button') }}>Yes</button>

                                                                                        <button className="chat_a" type="button" onClick={() => { onButtonSelect(message.buttons[1], 'text_button') }}>No</button>
                                                                                    </div>
                                                                                </div>}
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>}

                                                                {user.type == 'hiring_no_continue_feedback' && <div className="jon_de4" style={user.checkEnable == true ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                                                    {user.msg && user.msg.map((message, index) => {
                                                                        return (message.text && <p>{message.text}</p>
                                                                        )
                                                                    })}
                                                                    <div className="bor56">
                                                                        <textarea name="requirement_textarea" value={feedbackFromHiring} onChange={handleFeedbackFromHiring} />
                                                                    </div>
                                                                    <div className="btn_ok"><button className="chat_a" type="button" onClick={() => { submitData(feedbackFromHiring, 21, 'hiring_no_continue_feedback') }}>Ok. I’m done</button></div>
                                                                </div>}

                                                                {user.type == 'approach_hiring_negotiate_offer' && 
                                                                <div className="dhs_step1 step72">
                                                                    <div className="main_outr5">
                                                                        {user.msg && user.msg.map((message, index) => {
                                                                            return (message.text && <p>{message.text}</p>
                                                                            )
                                                                        })}
                                                                        {monthlyBasicForm && <p><b>Offered basic salary : </b>${monthlyBasicForm} per month</p>}
                                                                        {annualWageForm && <p><b>Annual wage supplement : </b>${annualWageForm}</p>}
                                                                        {additionalPrivilegesForm && <p><b>Additional component : </b>{additionalPrivilegesForm}</p>}
                                                                        {roleOfferInput && <p><b>Job Title : </b>{roleOfferInput}</p>}
                                                                        {additionalWageComponentForm && <p><b>Commission : </b>{additionalWageComponentForm}</p>}
                                                                        <p><b>Negotiable? : </b>{offerYesNegotiableForm == "yes" ? "Yes" : "No"}</p>

                                                                        <p></p>
                                                                        <br></br>

                                                                        {inputCustomSection && inputCustomSection.map((innermsg, ind) => {
                                                                            return(
                                                                            <div>
                                                                                {innermsg.text && <p>{innermsg.text}</p>}
                                                                                {innermsg.custom && <p>Expected Salary : ${innermsg.custom.expected_salary} per month</p>}
                                                                            </div>)
                                                                        })}

                                                                    </div>
                                                                </div>}

                                                                {user.type == 'approach_hiring_negotiate_offer_buttons' && <div className="dhs_step1 step5_no" style={user.checkEnable == true ? { pointerEvents: "none", opacity: "0.4" } : {}}>

                                                                    {offerAcceptedButtons && offerAcceptedButtons.map((message, index) => {
                                                                        return (<div>
                                                                            {message.text && <p>{message.text}</p>}
                                                                            {message.buttons && message.buttons.map((btn, ind) => {
                                                                                return (<div className="btn6_outer">
                                                                                    <button className="chat_a" onClick={() => { onButtonSelect(btn, 'approach_hiring_negotiate_offer_buttons') }}>{btn.title}</button>
                                                                                </div>)
                                                                            })}
                                                                        </div>
                                                                        )
                                                                    })}
                                                                </div>}

                                                                {user.type == 'candidate_personal_details' && <div className="dhs_step1 step73">
                                                                    <p>{user.msg[0].text}</p>
                                                                    <div className="inner560">
                                                                        <h4>Contact Number:</h4>
                                                                        <p>{user.msg[1].custom.email}</p>
                                                                    </div>
                                                                    <div className="inner560">
                                                                        <h4>Email:</h4>
                                                                        <p>{user.msg[1].custom.contact}</p>
                                                                    </div>
                                                                </div>}
                                                                
                                                                {user.type == 'initial_company_candidate_questions' && <div className="dhs_step1 step30" style={user.checkEnable == true ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                                                    <p>{user.msg[0].text}</p>
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            {candidateQuestionsHiring && candidateQuestionsHiring.map((message, index) => {
                                                                                return (
                                                                                    <div className="commis_outer yes_outer56 int5"><h5>Question {index + 1}:</h5>
                                                                                        <p>{message.text}</p>
                                                                                        <div className="yes_ouy67">
                                                                                            <button type="button" className={`${message.yesclass}`} value={`/affirm_${message.text}`} onClick={() => { hiringQuestionAnswer(`/affirm_${message.text}`, 11) }}>Yes</button>
                                                                                            <button type="button" className={`${message.noclass}`} value={`/deny_${message.text}`} onClick={() => { hiringQuestionAnswer(`/deny_${message.text}`, 11) }}>No</button>
                                                                                        </div>
                                                                                    </div>)
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                    <div className="skip_outer45 thr_btn">
                                                                        <div className="btn_ok"><button className="chat_a" type="button" onClick={() => { submitData(candidateQuestionsHiring, 11, 'initial_company_candidate_questions') }}>Ok. I’m done</button></div></div>
                                                                </div>}

                                                                {user.type == 'candidate_email_referee' && <div className="dhs_step2" style={user.checkEnable == true ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                                                    <p>Please enter his/her name and email address below</p>
                                                                    <div className="commis_outer">
                                                                        <h5>Name of Referee:</h5>
                                                                        <input type="text" name="nameReferee" value={nameReferee} onChange={handleNameRefereeChange}></input>
                                                                    </div>
                                                                    <div className="commis_outer comis_text6">
                                                                        <h5>Email address of Referee:</h5>
                                                                        <input type="email" name="emailReferee" value={emailReferee} onChange={handleEmailRefereeChange}></input>
                                                                    </div>
                                                                    <div className='skip_outer45'>
                                                                        <div className="btn_ok"><button className="chat_a" type="button" onClick={() => { submitData(emailReferee, 12, 'candidate_email_referee') }}>Ok. I’m done</button></div>
                                                                    </div>
                                                                </div>}

                                                                {user.type == 'offered_candidate_options' && <div>
                                                                    <div className="dhs_step1 step72">
                                                                        <div className="main_outr5">
                                                                            <p><b>Offered basic salary : </b>{monthlyBasicForm}</p>
                                                                            <p><b>Annual wage supplement : </b>{annualWageForm}</p>
                                                                            <p><b>Additional component : </b>{additionalPrivilegesForm}</p>
                                                                            <p><b>Job Title : </b>{roleOfferInput}</p>
                                                                            <p><b>Commission : </b>{additionalWageComponentForm}</p>
                                                                            <p><b>Negotiable? : </b>{offerYesNegotiableForm == "yes" ? "Yes" : "No"}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="dhs_step1 step72" style={user.checkEnable == true ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                                                        <div className="main_outr5">
                                                                            {user.msg && user.msg.map((message, index) => {
                                                                                return (
                                                                                    <div>
                                                                                        {message.text && <p>{message.text}</p>}
                                                                                        {message.buttons && message.buttons.map((btn, ind) => {
                                                                                            return (<div className="btn6_outer">
                                                                                                <button className="chat_a" onClick={() => { onButtonSelect(btn, 'offered_candidate_options') }}>{btn.title}</button>
                                                                                            </div>)
                                                                                        })}

                                                                                    </div>
                                                                                )
                                                                                
                                                                            })}

                                                                        </div>
                                                                    </div>
                                                                </div>}

                                                                {user.type == 'preferred_start_date_appointment' && <div className="dhs_step1 show1" style={user.checkEnable == true ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                                                    {user.msg && user.msg.map((message, index) => {
                                                                        return(
                                                                            message.text && <p>{message.text}</p> 
                                                                        )
                                                                    })}

                                                                    <div className="select_field custom_field12">
                                                                        <div className="row">
                                                                            <div className="col-md-6">
                                                                                <div className="custom_input_field">
                                                                                    <label>Select a date</label>
                                                                                    <input type="date" name="dateSelect" value={dateSelection} onChange={handleDateRange} min={minDate} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="custom_input_field">
                                                                                    <label>Select a time</label>
                                                                                    <input type="time" id="appt" name="timeSelect" value={timeSelection} onChange={handleTimeRange} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-12 done_outer">
                                                                                <button className="chat_a" onClick={() => { submitData(dateSelection, 19, 'preferred_start_date_appointment') }}>Ok. I'm done</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>}

                                                                {user.type == 'application_completed_candidate' && <div className="dhs_step1 step5_c">
                                                                    {user.msg && user.msg.map((message, index) => {
                                                                        return (
                                                                            message.text && <p>{message.text}</p>
                                                                        )
                                                                    })}
                                                                    <div className="row">
                                                                        <div className="col-md-4">
                                                                            <div className="stepc_inner">
                                                                                <img src={step51} />
                                                                                <p>Practice Good
                                                                                    Nonverbal Communication</p>
                                                                            </div>

                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="stepc_inner">
                                                                                <img src={step52} />
                                                                                <p>Dress for the Job
                                                                                    or Company</p>
                                                                            </div>

                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="stepc_inner">
                                                                                <img src={step53} />
                                                                                <p>Take Care to Answer
                                                                                    the Questions</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>}

                                                                {user.type == 'reason_list_candidate_dropdown' && <div className="dhs_step1 step5" style={user.checkEnable == true ? { pointerEvents: "none", opacity: "0.4" } : {}}>

                                                                {user.msg && user.msg.map((message, index) => {
                                                                    return (<div>
                                                                        {message.text && <p>{message.text}</p>}
                                                                        {message.custom &&
                                                                            <div className="select_outer">
                                                                                <select id="cars" onChange={event => onJobSelectchange(event, 'reason_list_candidate_dropdown')}>
                                                                                    <option value="">Select reason</option>
                                                                                    {message.custom.data.map((data, ind) => {
                                                                                        return (
                                                                                            <option key={`${ind}`} value={`${data.value}_${data.label}`}>{data.label}</option>)
                                                                                    })}
                                                                                </select>
                                                                            </div>
                                                                        }

                                                                    </div>)
                                                                })}
                                                                </div>}

                                                                {user.type == 'expected_salary_candidate_input' && <div className="dhs_step1 step72">
                                                                    {user.msg && user.msg.map((message, index) => {
                                                                        return ( message.text && <p>{message.text}</p>)
                                                                    })}

                                                                    <div className="main_outr5">
                                                                        <p><b>Job Title : </b>{roleOfferInput}</p>
                                                                        <p><b>Industry : </b>{inputCustomSection}</p>
                                                                        <p><b>Average Salary : </b>SGD${additionalWageComponentForm}</p>
                                                                    </div>
                                                                </div>}

                                                                {user.type == 'expected_salary_candidate_input_show' && <div className="dhs_step2 dhstep3" style={user.checkEnable == true ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                                                    <p>{user.msg[0].text}</p>
                                                                    <div className="iner_inp89">
                                                                        <span className="plus45">SGD$</span><input type="number" name="expectedSalary" value={expectedSalary} onChange={handleExpectedSalaryChange} /><span>per month</span>
                                                                    </div>
                                                                    <div className="btn_ok"><button className="chat_a" type="button" onClick={() => { submitData(expectedSalary, 20, 'expected_salary_candidate_input_show') }}>Ok. I’m done</button></div>
                                                                </div>}

                                                            </div></div>) :
                                                            (
                                                                <div className="chat_left_inner1">
                                                                    <div className="chat_person_name">
                                                                        Jarvis
                                                                    </div>

                                                                    <img className="loader_img" src={dotted} />
                                                                </div>)
                                                        }
                                                        {user.datetime ? (<span className="time">{user.datetime}</span>) : <></>}

                                                    </div>
                                                </div>

                                            ) : (
                                                <div className="chat_left chat_right">
                                                    <div className="chat_left_inner2">
                                                        {
                                                            user.msg && user.msg.map((message, index) => {
                                                                return <p>{message.text}</p>
                                                            })}

                                                        {(justNow == true && user.msg) && <span className="time">Just now. Seen</span>}
                                                        {(justNow == false && user.msg && user.datetime) && <span className="time">{user.datetime}</span>}
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                ))}
                            </div>
                        </div>

                        {(userType.includes("job_position") || userType.includes("Enter Manually")) && <form onSubmit={handleSubmit} >
                            <div className="chat_botton">
                                <input type="text" placeholder="Ask Jarvis..." ref={textInput} onChange={e => setInputMessage(e.target.value)} value={inputMessage} ></input>
                                <button type="submit" className="send_i"><i className="far fa-paper-plane"></i></button>
                            </div>
                        </form>}

                    </div >
                </div >
                <ReactTooltip />
            </section >

        </div >
    );
}

export default ChatBotInterface;
